import { SliderConfigs } from '../models/domain/interfaces';

export const defaultSliderConfigs: SliderConfigs = {
  autoPlay: false,
  autoPlayInterval: 5000,
  animationDuration: 2500,
  animationType: 'slide',
  infinite: false,
  showCarouselTitle: false,
  numberOfElementsXL: 3,
  numberOfElementsLG: 3,
  numberOfElementsMD: 2,
  numberOfElementsSM: 1,
  numberOfElementsXS: 1,
  forceHideGoToArchive: false,
  forceHideDate: false,
};

export const eventSliderConfigs: SliderConfigs = {
  autoPlay: false,
  autoPlayInterval: 5000,
  animationDuration: 2500,
  animationType: 'slide',
  infinite: false,
  showCarouselTitle: true,
  numberOfElementsXL: 3,
  numberOfElementsLG: 3,
  numberOfElementsMD: 2,
  numberOfElementsSM: 1,
  numberOfElementsXS: 1,
  forceHideGoToArchive: false,
};

export const singleNewsSliderConfigs: SliderConfigs = {
  autoPlay: false,
  autoPlayInterval: 5000,
  animationDuration: 2500,
  animationType: 'slide',
  infinite: false,
  showCarouselTitle: true,
  numberOfElementsXL: 3,
  numberOfElementsLG: 3,
  numberOfElementsMD: 2,
  numberOfElementsSM: 1,
  numberOfElementsXS: 1,
  forceHideGoToArchive: false,
};

export const workshopSliderConfigs: SliderConfigs = {
  autoPlay: false,
  autoPlayInterval: 5000,
  animationDuration: 2500,
  animationType: 'slide',
  infinite: false,
  showCarouselTitle: true,
  numberOfElementsXL: 3,
  numberOfElementsLG: 3,
  numberOfElementsMD: 2,
  numberOfElementsSM: 1,
  numberOfElementsXS: 1,
  forceHideGoToArchive: false,
};

export const productSliderConfigs: SliderConfigs = {
  autoPlay: false,
  autoPlayInterval: 5000,
  animationDuration: 2500,
  animationType: 'slide',
  infinite: false,
  showCarouselTitle: true,
  numberOfElementsXL: 4,
  numberOfElementsLG: 3,
  numberOfElementsMD: 2,
  numberOfElementsSM: 1,
  numberOfElementsXS: 1,
  forceHideGoToArchive: false,
};

export const fromHumanNumberOfColumnsToRepeaterNumberOfColumns = (
  humanNumberOfColumns: number
): number => {
  return 12 / humanNumberOfColumns;
};
