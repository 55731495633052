import { TableCell, TableRow } from '@mui/material';
import { styled } from '@mui/material';

export const TableCellMUI = styled(
  (props: any) => (
    <TableCell className={props?.className} {...props}>
      {props?.children}
    </TableCell>
  ),
  {
    shouldForwardProp: (prop) => prop !== 'showPadding',
  }
)(({ theme, showPadding, className, children, ...props }) => {
  return {
    // padding: ${({ theme, showPadding }): string =>
    // `${theme.spacings.unit(showPadding ? 2 : 0)} ${theme.spacings.unit(
    //   showPadding ? 4 : 0
    // )}`};
    // border: 0;
  };
});

export const TableRowMUI = styled(
  (props: any) => (
    <TableRow className={props?.className} {...props}>
      {props?.children}
    </TableRow>
  ),
  {
    shouldForwardProp: (prop) => prop !== 'showPadding',
  }
)(({ theme, showPadding, className, children, ...props }) => {
  return {
    // border: 0;
    // background-color: ${({ theme, open }): string =>
    //   open ? theme.colors.tableCollapseBackground : theme.colors.white};
    // transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  };
});
