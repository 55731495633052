import { styled } from '@mui/material';

export const TitleDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacings.unit(8),
  justifyContent: 'space-between',
  [theme.breakpointsMediaQuery.up('md')]: {
    flexDirection: 'row',
  },
}));

export const Title = styled('h2')(({ theme }) => ({
  fontWeight: '200',
  fontSize: theme.fonts.utility.size(10),
  maxWidth: '50rem',
  color: theme.colors.primaryText,
  margin: `auto ${theme.spacings.unit(9)} ${theme.spacings.unit(6.9875)}`,
  [theme.breakpointsMediaQuery.up('md')]: {
    margin: 0,
  },
}));
