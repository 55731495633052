import React from 'react';
import { TableParams } from '../../models/interface';

import TableBody from './table-body/TableBody';
import TableBodyRow from './table-body-row/TableBodyRow';
import TableBodyRowCell from './table-body-row-cell/TableBodyRowCell';
import TableBodyRowCollapse from './table-body-row-collapse/TableBodyRowCollapse';
import TableContainer from './table-container/TableContainer';
import TableHeader from './table-header/TableHeader';
import TableHeaderCell from './table-header-cell/TableHeaderCell';
import TableIconCollapse from './table-icon-collapse/TableIconCollapse';
import TableLoading from './table-loading/TableLoading';

const Table = ({ collapse, children, className }: TableParams) => {
  if (!children || (children && children.length !== 2)) {
    return <span>Missing header o body. Check ADR!</span>;
  }

  const Header = children[0];
  const Body = children[1];

  return (
    <TableContainer className={className}>
      <Header.type {...Header.props} collapse={collapse || false}></Header.type>
      <Body.type {...Body.props} collapse={collapse || false}></Body.type>
    </TableContainer>
  );
};

export default Table;
export {
  TableBody,
  TableBodyRow,
  TableBodyRowCell,
  TableBodyRowCollapse,
  TableHeader,
  TableHeaderCell,
  TableIconCollapse,
  TableLoading,
};
