import React from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';

import { getServerSideProps as CardTopNewsGetServerSideProps } from './news-and-events/card-top-news/CardTopNews';
import { getServerSideProps as CardTopWorkshopGetServerSideProps } from './workshops/card-top-workshop/CardTopWorkshop';
import { getServerSideProps as FormWorkshopGetServerSideProps } from './workshop/form-workshop/FormWorkshop';
import { getServerSideProps as NewsArchiveGetServerSideProps } from './news-archive/NewsArchive';
import { getServerSideProps as SliderEventsGetServerSideProps } from './news-and-events/slider-events/EventsTable';
import { getServerSideProps as SliderNewsEventsGetServerSideProps } from './home/slider-news-events/SliderNewsEvents';
import { getServerSideProps as SliderNewsGetServerSideProps } from './news-and-events/slider-news/SliderNews';
import { getServerSideProps as SliderPastEventsGetServerSideProps } from './news-and-events/slider-past-events/PastEventsTable';
import { getServerSideProps as SliderWorkshopGetServerSideProps } from './home/slider-workshop/SliderWorkshop';
import { getServerSideProps as WorkshopGetServerSideProps } from './workshop/Workshop';
import { getServerSideProps as WorkshopsTableSliderGetServerSideProps } from './workshops/workshops-table-slider/WorkshopsTableSlider';
import { getServerSideProps as SliderProductForNewsGetServerSideProps } from './news/slider-products-for-news/SliderProductForNews';
import { getServerSideProps as NewsEventsGetServerSideProps } from './home/news-events/NewsEvents';
import { getServerSideProps as WorkshopDisplayGetServerSideProps } from './home/workshop-display/WorkshopDisplay';
import { StrapiComponentProps, SupportInfo } from '../models/domain/interfaces';
import { UserContext } from '../models/interface';

const MarkdownEditor = dynamic(
  () => import('./markdown-editor/MarkdownEditor')
);
const NewsLetter = dynamic(() => import('./news-letter/NewsLetter'));
const GridSingleRow = dynamic(
  () => import('./home/grid-single-row/GridSingleRow')
);
const ProductSeparated = dynamic(
  () => import('./products/product-separated/ProductSeparated')
);
const WorkshopDisplay = dynamic(
  () => import('./home/workshop-display/WorkshopDisplay')
);
const SectionHeader = dynamic(
  () => import('./generic/section-header/SectionHeader')
);
const NewsEvents = dynamic(() => import('./home/news-events/NewsEvents'));
const BoxSection = dynamic(() => import('./home/box-section/BoxSection'));
const SliderTop = dynamic(() => import('./home/slider-top/SliderTop'));
const GridProducts = dynamic(() => import('./home/grid/Grid'));
const SliderProducts = dynamic(
  () => import('./home/slider-products/SliderProducts')
);
const SliderWorkshop = dynamic(
  () => import('./home/slider-workshop/SliderWorkshop')
);
const ProductPresentationHome = dynamic(
  () => import('./home/product-presentation/productPresentation')
);
const SliderBio = dynamic(() => import('./home/slider-bio/SliderBio'));
const History = dynamic(() => import('./home/history/history'));
const SliderNewsEvents = dynamic(
  () => import('./home/slider-news-events/SliderNewsEvents')
);
const ServicesOffers = dynamic(
  () => import('./home/services-offers/ServicesOffers')
);
const ProductDetails = dynamic(
  () => import('./products/product-details/ProductDetails')
);
const ProductDetailsFls = dynamic(
  () => import('./products/product-details-fls/ProductDetailsFls')
);
const ProductMedia = dynamic(
  () => import('./products/product-media/ProductMedia')
);
const ProductPresentation = dynamic(
  () => import('./products/product-presentation/ProductPresentation')
);
const ProductSuggestions = dynamic(
  () => import('./products/product-suggestions/ProductSuggestions')
);
const ProductSeoTags = dynamic(
  () => import('./products/product-seoTags/ProductSeoTags')
);
const CardTopNews = dynamic(
  () => import('./news-and-events/card-top-news/CardTopNews')
);
const SliderEvents = dynamic(
  () => import('./news-and-events/slider-events/EventsTable')
);
const SliderPastEvents = dynamic(
  () => import('./news-and-events/slider-past-events/PastEventsTable')
);
const SliderNews = dynamic(
  () => import('./news-and-events/slider-news/SliderNews')
);
const Event = dynamic(() => import('./event/Event'));
const SingleNews = dynamic(() => import('./news/SingleNews'));
const CardBioMobile = dynamic(
  () => import('../components/cards/card-bio/CardBio')
);
const CategoryHeader = dynamic(() => import('./category/header/Header'));
const CategoryContent = dynamic(() => import('./category/content/Content'));
const AboutHeader = dynamic(() => import('./about/header/Header'));
const NewsArchive = dynamic(() => import('./news-archive/NewsArchive'));
const AboutCompany = dynamic(
  () => import('./about/about-company/AboutCompany')
);
const AboutFactories = dynamic(
  () => import('./about/about-factories/AboutFactories')
);
const AboutFactoriesList = dynamic(
  () => import('./about/about-factories-list/AboutFactoriesList')
);
const TermsAndConditions = dynamic(
  () => import('./terms-and-conditions/TermsAndConditions')
);
const PrivacyAndCookie = dynamic(
  () => import('./privacy-and-cookie/PrivacyAndCookie')
);
const ContactHeader = dynamic(() => import('./contacts/header/Header'));
const ContactHeaderVideo = dynamic(
  () => import('./contacts/header-video/HeaderVideo')
);
const ContactForm = dynamic(() => import('./contacts/form/Form'));
const ContactLocations = dynamic(
  () => import('./contacts/locations/Locations')
);
const Workshop = dynamic(() => import('./workshop/Workshop'));
const CardTopWorkshop = dynamic(
  () => import('./workshops/card-top-workshop/CardTopWorkshop')
);
const WorkshopsBottomText = dynamic(
  () => import('./workshops/workshops-bottom-text/WorkshopsBottomText')
);
const WorkshopsTable = dynamic(
  () => import('./workshops/workshops-table/WorkshopsTable')
);
const WorkshopsTableSlider = dynamic(
  () => import('./workshops/workshops-table-slider/WorkshopsTableSlider')
);
const FormWorkshop = dynamic(
  () => import('./workshop/form-workshop/FormWorkshop')
);
const WorkWithUsBanner = dynamic(
  () => import('./work-with-us/work-with-us-banner/WorkWithUsBanner')
);
const WorkWithUsBannerVideo = dynamic(
  () => import('./work-with-us/work-with-us-banner-video/WorkWithUsBannerVideo')
);
const WorkWithUsForm = dynamic(
  () => import('./work-with-us/work-with-us-form/WorkWithUsForm')
);
const WorkWithUsInfo = dynamic(
  () => import('./work-with-us/work-with-us-info/WorkWithUsInfo')
);
const Iot4KeyPoints = dynamic(
  () => import('./iot/iot-4-key-points/Iot4KeyPoints')
);
const Iot4KeyPointsGrid = dynamic(
  () => import('./iot/iot-4-key-points-grid/Iot4KeyPointsGrid')
);
const Informations = dynamic(() => import('./iot/informations/Informations'));
const InformationsIcon = dynamic(
  () => import('./iot/informations-icon/InformationsIcon')
);
const GoToApp = dynamic(() => import('./iot/go-to-app/GoToApp'));
const SliderProductForNews = dynamic(
  () => import('./news/slider-products-for-news/SliderProductForNews')
);

const ImageSlider = dynamic(() => import('./iot/image-slider/ImageSlider'));
const NotFound = dynamic(() => import('./errors/not-found/NotFound'));
const Internal = dynamic(() => import('./errors/internal/Internal'));
const HeaderVideo = dynamic(() => import('./generic/banner-video/HeaderVideo'));

const Breadcrumb = dynamic(() => import('./breadcrumb/Breadcrumb'));

const CookieBotScript = dynamic(
  () => import('./privacy-and-cookie/cookie-bot-script/CookieBotScript')
);

// Map Strapi sections to section components
export const sectionComponents: any = {
  'componenti.section-header': SectionHeader,
  'home.workshop-display': {
    component: WorkshopDisplay,
    getServerSideProps: WorkshopDisplayGetServerSideProps,
  },
  'home.news-events': {
    component: NewsEvents,
    getServerSideProps: NewsEventsGetServerSideProps,
  },
  'home.box-section': BoxSection,
  'home.history': History,
  'home.product-presentation': ProductPresentationHome,
  'home.grid-products-single-row': GridSingleRow,
  'home.slider-top': SliderTop,
  'home.grid-products': GridProducts,
  'home.slider-products': {
    component: SliderProducts,
  },
  'home.slider-workshop': {
    component: SliderWorkshop,
    getServerSideProps: SliderWorkshopGetServerSideProps,
  },
  'home.slider-bio': SliderBio,
  'home.card-bio': CardBioMobile,
  'home.slider-news-eventi': {
    component: SliderNewsEvents,
    getServerSideProps: SliderNewsEventsGetServerSideProps,
  },
  'home.servizi-offerte': ServicesOffers,
  'product.separated': ProductSeparated,
  'product.presentation': ProductPresentation,
  'product.media': ProductMedia,
  'product.details': ProductDetails,
  'product.details-fls': ProductDetailsFls,
  'product.suggestions': ProductSuggestions,
  'product.seoTags': ProductSeoTags,
  'news.card-news': {
    component: CardTopNews,
    getServerSideProps: CardTopNewsGetServerSideProps,
  },
  'events.events-slider': {
    component: SliderEvents,
    getServerSideProps: SliderEventsGetServerSideProps,
  },
  'events.slider-past-events': {
    component: SliderPastEvents,
    getServerSideProps: SliderPastEventsGetServerSideProps,
  },
  'news.news-slider': {
    component: SliderNews,
    getServerSideProps: SliderNewsGetServerSideProps,
  },
  'event.event': Event,
  'news.single-news': SingleNews,
  'category.header': CategoryHeader,
  'category.content': CategoryContent,
  'news.news-archive': {
    component: NewsArchive,
    getServerSideProps: NewsArchiveGetServerSideProps,
  },
  'news-letter.news-letter': NewsLetter,
  'componenti.card': AboutHeader,
  'componenti.card-video': HeaderVideo,
  'componenti.markdown': MarkdownEditor,
  'about.about': AboutCompany,
  'about.about-sirman-factories': AboutFactories,
  'about.factory-list': AboutFactoriesList,
  'terms-conditions.terms-and-conditions': TermsAndConditions,
  'privacy-cookie.privacy-and-cookie-policy': PrivacyAndCookie,
  'contact-us.banner-contacts': ContactHeader,
  'contact-us.banner-video-contacts': ContactHeaderVideo,
  'contact-us.contact-us-form': ContactForm,
  'contact-us.locations': ContactLocations,
  'error.not-found': NotFound,
  'error.internal': Internal,
  'workshops.workshop': {
    component: Workshop,
    getServerSideProps: WorkshopGetServerSideProps,
  },
  'workshops.slider-workshop': {
    component: SliderWorkshop,
    getServerSideProps: SliderWorkshopGetServerSideProps,
  },
  'workshops.card-top-workshop': {
    component: CardTopWorkshop,
    getServerSideProps: CardTopWorkshopGetServerSideProps,
  },
  'workshops.workshops-bottom-text': WorkshopsBottomText,
  'workshops.workshop-table': WorkshopsTable,
  'workshops.workshops-table-slider': {
    component: WorkshopsTableSlider,
    getServerSideProps: WorkshopsTableSliderGetServerSideProps,
  },
  'workshops.workshop-form-component': {
    component: FormWorkshop,
    getServerSideProps: FormWorkshopGetServerSideProps,
  },
  'work-with-us.work-with-us-banner': WorkWithUsBanner,
  'work-with-us.work-with-us-info': WorkWithUsInfo,
  'work-with-us.work-with-us-form': WorkWithUsForm,
  'news.slider-products-for-news': {
    component: SliderProductForNews,
    getServerSideProps: SliderProductForNewsGetServerSideProps,
  },
  'iot.iot-4-key-points': Iot4KeyPoints,
  'iot.iot-4-key-points-grid': Iot4KeyPointsGrid,
  'iot.image-slider': ImageSlider,
  'iot.informations': Informations,
  'iot.informations-icon': InformationsIcon,
  'iot.go-to-app': GoToApp,
  'work-with-us.work-with-us-banner-video': WorkWithUsBannerVideo,
  'componenti.breadcrumb': Breadcrumb,
  'privacy-cookie.cookie-bot-script': CookieBotScript,
};

// TODO: NO HERE
// Display a section individually
const Section = ({
  sectionData,
  translations,
  privacyPolicy,
  metadata,
  support,
  userContext,
  isPrerenderRequest,
}: any) => {
  let SectionComponent;

  if (isPrerenderRequest) {
    delete sectionComponents['privacy-cookie.cookie-bot-script'];
    delete sectionComponents['privacy-cookie.privacy-and-cookie-policy'];
    SectionComponent =
      sectionComponents[sectionData?.__component].component ??
      sectionComponents[sectionData?.__component];
  } else {
    SectionComponent =
      sectionComponents?.[sectionData?.__component]?.component ??
      sectionComponents[sectionData?.__component];
  }

  if (!SectionComponent) {
    return null;
  }

  // Display the section
  return (
    <SectionComponent
      data={sectionData}
      translations={translations}
      privacyPolicy={privacyPolicy}
      metadata={metadata}
      support={support}
      userContext={userContext}
      isPrerenderRequest={isPrerenderRequest}
    />
  );
};

// TODO: NO HERE
const PreviewModeBanner: React.FC = () => {
  const router = useRouter();

  return (
    <div>
      <div>
        Preview mode is on.{' '}
        <a
          className="underline"
          href={`/api/exit-preview?redirect=${router.asPath}`}
        >
          Turn off
        </a>
      </div>
    </div>
  );
};

// Display the list of sections
const Sections = ({
  sections,
  preview,
  translations,
  privacyPolicy,
  metadata,
  support,
  userContext,
  isPrerenderRequest,
}: {
  sections: StrapiComponentProps[];
  preview: any;
  translations: any;
  privacyPolicy: {
    text: string;
    link: string;
  };
  metadata: any;
  support: SupportInfo;
  userContext: UserContext;
  isPrerenderRequest: boolean;
}) => {
  return (
    <div className="flex flex-col">
      {/* Show a banner if preview mode is on */}
      {preview && <PreviewModeBanner />}
      {/* Show the actual sections */}
      {sections?.map((section) => (
        <Section
          sectionData={section}
          key={`${section.__component}${section.id}`}
          translations={translations}
          privacyPolicy={privacyPolicy}
          metadata={metadata}
          support={support}
          userContext={userContext}
          isPrerenderRequest={isPrerenderRequest}
        />
      ))}
    </div>
  );
};

export default Sections;
