import {
  Table as TableMUI,
  TableContainer as TableContainerMUI,
} from '@mui/material';

const TableContainer = ({
  children,
  component,
  className,
}: {
  children?: any;
  component?: string | any;
  className?: string;
}) => {
  return (
    <TableContainerMUI component={component} className={className}>
      <TableMUI>{children}</TableMUI>
    </TableContainerMUI>
  );
};

export default TableContainer;
