import { styled } from '@mui/material';

export const FlexContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'showCarouselTitle',
})(({ theme, showCarouselTitle }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpointsMediaQuery.up('md')]: {
    flexDirection: 'row',
    justifyContent: !showCarouselTitle ? 'flex-end' : 'space-between',
    margin: `${theme.spacings.unit(2)} 0`,
  },
}));

export const Title = styled('h2')(({ theme }) => ({
  fontWeight: '200',
  fontSize: theme.fonts.utility.size(9),
  maxWidth: '50rem',
  color: theme.colors.primaryText,
  margin: `auto ${theme.spacings.unit(9)} ${theme.spacings.unit(6.9875)}`,
  [theme.breakpointsMediaQuery.up('md')]: {
    margin: 0,
  },
}));

export const StyledLink = styled('button')(({ theme }) => ({
  background: 'transparent',
  border: `${theme.spacings.unit(1 / 4)} solid ${theme.colors.primary}`,
  alignSelf: 'center',
  textAlign: 'center',
  padding: `${theme.spacings.unit(2.5)} ${theme.spacings.unit(5.75)}`,
  backgroundColor: theme.colors.primary,
  color: theme.colors.white,
  letterSpacing: theme.spacings.unit(0.075),
  fontSize: theme.fonts.utility.size(8),
  '&:hover': {
    background: theme.colors.primary,
    border: `${theme.spacings.unit(1 / 4)} solid ${theme.colors.primary}`,
    color: theme.colors.white,
    cursor: 'pointer',
  },
}));

export const RepeaterWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacings.unit(12),
}));

export const LinkWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'inline-flex',
  justifyContent: 'center',
  marginTop: theme.spacings.unit(6.5),
}));
