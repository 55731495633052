import React from 'react';
import { Field as FieldFormik, ErrorMessage, FieldAttributes } from 'formik';
import { ErrorStyled, FieldWrapper } from './Field.style';

const Field: React.FC<FieldAttributes<any>> = ({
  ...props
}: FieldAttributes<any>) => {
  return (
    <FieldWrapper>
      <FieldFormik {...props} />
      {!props.hideError && props.errorCustom === undefined && (
        <ErrorStyled>
          <ErrorMessage name={props.name} />
        </ErrorStyled>
      )}
      {props.errorCustom !== undefined && props.errorCustom && (
        <ErrorStyled>{props?.errorMessage}</ErrorStyled>
      )}
    </FieldWrapper>
  );
};

export default Field;
