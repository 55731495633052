import React from 'react';
import Link from 'next/link';
import { BreadcrumbJsonLd } from 'next-seo';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  capitalizeFirstLetter,
  toKebabCaseWithAccentsCyrillicCJK,
} from 'corporate-utils';

import {
  BreadCrumbsStyled,
  LinkStyled,
  TypographyStyled,
} from './Breadcrumb.style';
import { getCompany } from '../../utils/company';
import { Companies } from '../../models/domain/enum';
import { Slash } from '../../assets';

interface IProps {
  links: {
    href: string;
    name: string;
    color?: string;
    disabled?: boolean;
  }[];
  pageActive: {
    name: string;
    color?: string;
  };
  lightContrast?: boolean;
}

const Breadcrumb: React.FC<IProps> = ({
  links,
  pageActive,
  lightContrast,
}: IProps) => {
  const mapToBreadcrumbJsonLd = () => {
    const activeLinkPosition = links?.length + 1;
    const activeLinkUrl =
      activeLinkPosition > 1
        ? `${
          links?.[links?.length - 1]?.href
        }/${toKebabCaseWithAccentsCyrillicCJK(pageActive?.name)}`
        : `${toKebabCaseWithAccentsCyrillicCJK(pageActive?.name)}`;

    return [
      ...links?.map?.((link, index) => ({
        position: index + 1,
        name: capitalizeFirstLetter(link?.name),
        item: `${process.env.NEXT_PUBLIC_URL}${link?.href}`,
      })),
      {
        position: activeLinkPosition,
        name: capitalizeFirstLetter(pageActive?.name),
        item: `${process.env.NEXT_PUBLIC_URL}${activeLinkUrl}`,
      },
    ];
  };

  return (
    <>
      <BreadCrumbsStyled
        aria-label="breadcrumb"
        color={pageActive?.color}
        lightContrast={!!lightContrast}
        separator={
          getCompany() === Companies.SIRMAN ? (
            <NavigateNextIcon fontSize="small" />
          ) : (
            <Slash />
          )
        }
      >
        {links?.map((link, index) => (
          <Link key={index} href={link?.href} passHref>
            <LinkStyled
              disabled={link?.disabled}
              underline="none"
              color={link?.color}
              lightContrast={!!lightContrast}
            >
              {capitalizeFirstLetter(link?.name?.replace?.(/-/g, ' '))}
            </LinkStyled>
          </Link>
        ))}
        <TypographyStyled
          color={pageActive.color}
          lightContrast={!!lightContrast}
        >
          {capitalizeFirstLetter(pageActive?.name?.replace?.(/-/g, ' '))}
        </TypographyStyled>
      </BreadCrumbsStyled>
      <BreadcrumbJsonLd itemListElements={mapToBreadcrumbJsonLd()} />
    </>
  );
};

export default Breadcrumb;
