import { styled } from '@mui/material';
import Carousel from 'react-material-ui-carousel';

import { ContentWrapper } from '../../../styles/shared';

export const StyledContentWrapper = styled(ContentWrapper)(({ theme }) => ({
  color: theme.colors.secondaryText,
  '@media print': {
    display: 'none',
  },
  margin: `${theme.spacings.unit(8)} auto`,
}));

export const StyledCarousel = styled(Carousel)<any>(({ theme }) => ({
  width: '100%',
}));
