import {
  CardStyled,
  FieldContainer,
  FieldHeader,
  FieldText,
  StyledLinkMobile,
} from './CardWorkshopsMobile.style';
import React from 'react';
import { useTheme } from '../../../theme';
import { getStrapiMedia } from '../../../utils/media';
import Link from 'next/link';
import { DEFAULT_IMAGE } from '../../../models/domain/const';
import ImageWithFallback from '../../image-with-fallback/ImageWithFallback';

export interface CardWorkshopsData {
  id: number;
  title: string;
  image?: any;
  date?: Date;
  slug: any;
}

interface CardProps {
  data: CardWorkshopsData;
  translations: any;
  isPrerenderRequest: boolean;
}

const CardWorkshopsMobile: React.FC<CardProps> = ({
  data,
  translations,
  isPrerenderRequest,
}) => {
  const theme = useTheme();
  const { image, title, date, slug } = data || {};

  return (
    <Link href={`/${translations?.urls?.workshops}/${slug}`} passHref>
      <CardStyled hasImage={!!image}>
        {image && image.data && image.data.attributes && (
          <ImageWithFallback
            src={getStrapiMedia(image?.data?.attributes?.url ?? '')}
            alt={image?.data?.attributes?.alternativeText || ''}
            layout="intrinsic"
            width={theme.state.theme.spacings.unit(80)}
            height={theme.state.theme.spacings.unit(40.5)}
            objectFit="contain"
            fallbackSrc={DEFAULT_IMAGE}
            isPrerenderRequest={isPrerenderRequest}
          />
        )}
        <FieldContainer>
          <FieldHeader>{translations?.pages?.workshops?.title}</FieldHeader>
          <FieldText>{title ?? ''}</FieldText>
        </FieldContainer>
        <FieldContainer>
          <FieldHeader>{translations?.pages?.workshops?.date}</FieldHeader>
          <FieldText>{date ?? ''}</FieldText>
        </FieldContainer>
        <FieldContainer>
          <FieldText>
            <Link href={`/${translations?.urls?.workshops}/${slug}`} passHref>
              <StyledLinkMobile>
                {translations?.pages?.workshops?.register}
              </StyledLinkMobile>
            </Link>
          </FieldText>
        </FieldContainer>
      </CardStyled>
    </Link>
  );
};

export default CardWorkshopsMobile;
