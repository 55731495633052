import { styled } from '@mui/material';

export const ErrorStyled = styled('div')(({ theme }) => ({
  marginRight: theme.spacings.unit(1),
  fontSize: theme.fonts.utility.size(6),
  margin: `${theme.spacings.unit(0.25)} 0`,
  color: theme.colors.primaryError,
  minHeight: theme.spacings.unit(4),
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const FieldWrapper = styled('div', {
  shouldForwardProp: (props) => props !== 'styleType',
})(({ theme, inline }) => ({
  flex: '1',
  flexDirection: 'column',
  [theme.breakpointsMediaQuery.up('md')]: {
    paddingRight: inline ? theme.fonts.utility.size(1) : '',
  },
  [theme.breakpointsMediaQuery.down('md')]: {
    paddingRight: inline ? theme.fonts.utility.size(1) : '',
  },
}));
