import { FormControlLabel, Radio, RadioGroup, styled } from '@mui/material';
import { TextSize } from '../../../models/enum';
import { RadioType } from './Radio';

export const RadioGroupStyled = styled(
  (props: any) => <RadioGroup {...props} />,
  {
    shouldForwardProp: (prop) =>
      !['textSize', 'radioType'].includes(prop as string),
  }
)(({ theme, radioType, disabled, textSize }) => {
  radioType = disabled ? RadioType.disabled : radioType;

  const disabledStyle = {
    color: theme.colors.textDisabled,
    fill: theme.colors.disabled,
    background: theme.colors.disabled,
  };

  const primaryStyle = {
    color: theme.colors.white,
    fill: theme.colors.white,
    background: theme.colors.primary,
  };

  const secondaryStyle = {
    color: theme.colors.primary,
    fill: theme.colors.primary,
    background: theme.colors.background,
  };

  const workshopStyle = {
    color: theme.colors.primary,
    fill: theme.colors.primary,
    background: theme.colors.primary,
  };

  const contactFormModalStyle = {
    color: theme.colors.primary,
    fill: theme.colors.primary,
    background: theme.colors.white,
  };

  const contactFormPageStyle = {
    color: theme.colors.black,
    fill: theme.colors.black,
    background: theme.colors.white,
  };

  const smallStyle = {
    fontSize: '0.8rem',
  };

  const mediumStyle = {
    fontSize: '1rem',
  };

  const largeStyle = {
    fontSize: '1.2rem',
  };

  const contactFormFontSize = {
    fontSize: '0.9rem',
  };

  const workshopFontSize = {
    fontSize: '0.9rem',
  };

  const typeStyle = () => {
    switch (radioType) {
      case RadioType.primary:
      default:
        return primaryStyle;
      case RadioType.secondary:
        return secondaryStyle;
      case RadioType.disabled:
        return disabledStyle;
      case RadioType.contactFormModal:
        return contactFormModalStyle;
      case RadioType.contactFormPage:
        return contactFormPageStyle;
      case RadioType.workshop:
        return workshopStyle;
    }
  };

  // Not used
  const sizeStyle = () => {
    switch (textSize) {
      case TextSize.small:
        return smallStyle;
      case TextSize.medium:
      default:
        return mediumStyle;
      case TextSize.large:
        return largeStyle;
      case TextSize.contactForm:
        return contactFormFontSize;
      case TextSize.workshop:
        return workshopFontSize;
    }
  };

  return {
    '& path': {
      fill: typeStyle().fill,
    },
  };
});

export const FormControlLabelStyled = styled((props: any) => (
  <FormControlLabel {...props} />
))(({ theme }) => {
  return {};
});

export const RadioStyled = styled((props: any) => <Radio {...props} />)(
  ({ theme }) => {
    return {};
  }
);

export const RadioSectionStyled = styled('section')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
}));

export const RadioLabelStyled = styled('section', {
  shouldForwardProp: (prop) => !['radioType'].includes(prop as string),
})(({ theme, radioType }) => {
  const defaultStyle = {
    color: theme.colors.primaryText,
  };

  const contactFormModalStyle = {
    color: theme.colors.primaryText,
  };

  const getStyle = (): any => {
    switch (radioType) {
      default:
        return defaultStyle;
      case RadioType.contactFormModal:
        return contactFormModalStyle;
    }
  };

  return {
    fontSize: theme.fonts.utility.size(8),
    margin: `${theme.spacings.unit(1)} 0`,
    fontWeight: 600,
    ...getStyle(),
  };
});

export const RadioLabelStyledSub = styled('section', {
  shouldForwardProp: (prop) => !['radioType'].includes(prop as string),
})(({ theme, radioType }) => {
  const defaultStyle = {
    color: theme.colors.primaryText,
  };

  const contactFormModalStyle = {
    color: theme.colors.primaryText,
  };

  const getStyle = (): any => {
    switch (radioType) {
      default:
        return defaultStyle;
      case RadioType.contactFormModal:
        return contactFormModalStyle;
    }
  };

  return {
    fontSize: theme.fonts.utility.size(7),
    margin: `${theme.spacings.unit(1)} 0`,
    fontWeight: 500,
    ...getStyle(),
  };
});
