import { styled } from '@mui/material';

export const MarkdownWrapper = styled('div', {
  shouldForwardProp: (prop) => !['lightContrast'].includes(prop as string),
})(({ theme, lightContrast }) => ({
  fontSize: theme.fonts.utility.size(9),
  lineHeight: theme.spacings.unit(27 / 4),
  display: 'flex',
  justifyContent: 'center',
  color: lightContrast ? theme.colors.white : theme.colors.primaryText,
  fontWeight: 400,
  '> div': {
    display: 'flex',
    maxWidth: theme.spacings.unit(626 / 4),
    flexDirection: 'column',
    [theme.breakpointsMediaQuery.down('sTextNews')]: {
      maxWidth: '100%',
    },
  },
  '> div:first-of-type': {
    padding: 0,
  },
  p: {
    margin: `${theme.fonts.utility.size(8)} 0 0 0`,
  },
}));

export const MarkdownWrapperSimple = styled('div', {
  shouldForwardProp: (prop) => !['lightContrast'].includes(prop as string),
})(({ theme }) => ({
  fontSize: theme.fonts.utility.size(9),
  fontWeight: 400,
  color: theme.colors.primaryText,
  maxWidth: theme.spacings.unit(626 / 4),
  [theme.breakpointsMediaQuery.down('sTextNews')]: {
    maxWidth: '100%',
  },
  margin: 'auto',
}));
