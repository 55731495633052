import { TableBodyRowCell as TableBodyRowCellStyled } from './TableBodyRowCell.style';

const TableBodyRowCell = ({
  children,
  className,
  size,
  style,
}: {
  children?: any;
  className?: string;
  size?: 'icon' | 'medium' | 'small';
  style?: any;
}) => {
  return (
    <TableBodyRowCellStyled
      className={className}
      size={size}
      title={typeof children === 'string' ? children : ''}
      style={style}
    >
      {children}
    </TableBodyRowCellStyled>
  );
};

export default TableBodyRowCell;
