import { Collapse as CollapseMUI } from '@mui/material';

import { TableRowMUI, TableCellMUI } from './TableBodyRowCollapse.style';

const TableBodyRowCollapse = ({
  open,
  children,
  span,
}: {
  open?: boolean;
  children?: any;
  span?: number;
}) => {
  return (
    <TableRowMUI open={open}>
      <TableCellMUI showPadding={open} colSpan={span || 1}>
        <CollapseMUI in={open} unmountOnExit>
          {children}
        </CollapseMUI>
      </TableCellMUI>
    </TableRowMUI>
  );
};

export default TableBodyRowCollapse;
