import { styled } from '@mui/material/styles';
import { mergePersonalization } from '../../utils/style';

export const MainTitle = styled('div')(({ theme, personalization }) =>
  mergePersonalization(
    theme,
    {
      color: theme.colors.mainTitleText,
      textAlign: 'center',
      fontSize: theme.fonts.utility.size(7),
      fontStyle: 'normal',
      fontWeight: 900,
      lineHeight: '125%',
      letterSpacing: theme.spacings.unit(2.04),
      textTransform: 'uppercase',
      [theme.breakpointsMediaQuery.down('sm')]: {
        fontSize: theme.fonts.utility.size(7),
        fontWeight: 600,
        lineHeight: 1.1,
      },
    },
    personalization
  )
);

export const Title = styled('h2')(({ theme, personalization }) =>
  mergePersonalization(
    theme,
    {
      color: `${theme.colors.primaryText}`,
      textAlign: 'center',
      fontSize: theme.fonts.utility.size(18),
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '115%',
      letterSpacing: `${theme.spacings.unit(-0.25)}`,
      margin: 0,
      [theme.breakpointsMediaQuery.down('sm')]: {
        fontSize: theme.fonts.utility.size(13),
        fontWeight: 600,
      },
    },
    personalization
  )
);

export const IconContainerStyled = styled('div')(({ theme }) => ({
  position: 'relative',
  width: `${theme.spacings.unit(14)}`,
  aspectRatio: '1/1',
}));

export const HeaderWrapper = styled('div')(({ theme, personalization }) =>
  mergePersonalization(
    theme,
    {
      display: 'flex',
      flexDirection: 'column',
      gap: `${theme.spacings.unit(4)}`,
      alignItems: 'center',
      maxWidth: `calc(49% + ${theme.spacings.unit(28)})`,
      margin: '0 auto',
      paddingTop: theme.spacings.unit(24),
      paddingBottom: theme.spacings.unit(12),
      [theme.breakpointsMediaQuery.down('md')]: {
        gap: `${theme.spacings.unit(2)}`,
        paddingTop: theme.spacings.unit(14),
        paddingBottom: theme.spacings.unit(9),
      },
    },
    personalization
  )
);

export const Description = styled('h6')(({ theme, personalization }) =>
  mergePersonalization(
    theme,
    {
      whiteSpace: 'pre-line',
      color: `${theme.colors.secondaryText}`,
      fontSize: theme.fonts.utility.size(9),
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '160%',
      margin: 0,
      textAlign: 'center',
      [theme.breakpointsMediaQuery.down('sm')]: {
        fontSize: theme.fonts.utility.size(9),
        fontWeight: 400,
      },
    },
    personalization
  )
);
