import { styled } from '@mui/material';
import {
  TableBody as TableBodyComponent,
  TableBodyProps,
  TableCellClasses,
} from '@mui/material';

import TableHeaderCell from '../table-header-cell/TableHeaderCell';
import TableHeader from '../table-header/TableHeader';
import TableBody from './TableBody';

export const TableHeaderNested = styled(
  (props: any) => (
    <TableHeader className={props?.className} {...props}>
      {props?.children}
    </TableHeader>
  ),
  {}
)<TableBodyProps>(() => {
  return {};
});

export const TableHeaderCellNested = styled(
  (props: any) => (
    <TableHeaderCell className={props?.className} {...props}>
      {props?.children}
    </TableHeaderCell>
  ),
  {}
)<TableCellClasses>(() => {
  return {};
});

export const TableBodyNested = styled(
  (props: any) => (
    <TableBody className={props?.className} {...props}>
      {props?.children}
    </TableBody>
  ),
  {}
)(({ theme, className, children }) => {
  return {};
});

export const TableBodyMUI = styled(
  (props: any) => (
    <TableBodyComponent className={props?.className} {...props}>
      {props?.children}
    </TableBodyComponent>
  ),
  {}
)(({ theme, className, children }) => {
  return {};
});

export const TableBodyEmptyMessage = styled('p')(() => ({}));
