import { styled } from '@mui/material';
import Button from '../../../components/button/Button';
import { mergePersonalization } from '../../../utils/style';

export const Title = styled('h1')(({ theme, personalization }) =>
  mergePersonalization(
    theme,
    {
      fontSize: theme.fonts.utility.size(17.5),
      fontWeight: '500',
      textAlign: 'center',
      margin: `${theme.spacings.unit(10)} 0 0 0`,
      color: theme.colors.primaryText,
    },
    personalization
  )
);

export const Description = styled('h2')(({ theme, personalization }) =>
  mergePersonalization(
    theme,
    {
      fontWeight: '200',
      fontSize: theme.fonts.utility.size(12.5),
      margin: `${theme.spacings.unit(3)} 0 0`,
      textAlign: 'center',
    },
    personalization
  )
);

export const FormRowGroup = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacings.unit(4),
  marginBottom: theme.spacings.unit(4),
  '& > div > div': {
    minHeight: '1.1rem',
  },
  [theme.breakpointsMediaQuery.up('md')]: {
    flexDirection: 'row',
    alignItems: 'end',
    margin: 0,
    marginBottom: theme.spacings.unit(8),
  },
}));

export const ContentForm = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: theme.spacings.unit(210.5),
  margin: 'auto',
}));

export const FormBody = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: theme.spacings.unit(156.5),
  margin: 'auto',
  width: '100%',
  marginTop: `${theme.spacings.unit(10)}`,
}));

export const SubmitButton = styled(Button)(() => ({
  borderRadius: 0,
}));
