import { styled } from '@mui/material';
import Table from '../../../components/table/Table';
import React from 'react';
import { ContentWrapper } from '../../../styles/shared';

export const StyledContentWrapper = styled(ContentWrapper)(({ theme }) => ({
  marginBottom: theme.spacings.unit(16),
}));

export const TableStyled = styled((props: any) => (
  <Table className={props.className} {...props} />
))(({ theme }) => ({
  margin: `${theme.spacings.unit(4)} 0`,
}));

export const FlexContainer = styled('div')(({ title, theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpointsMediaQuery.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: `${theme.spacings.unit(2)} 0`,
    },
  };
});

export const Title = styled('h2')(({ theme }) => ({
  fontWeight: '300',
  fontSize: theme.fonts.utility.size(10),
  letterSpacing: theme.spacings.unit(0.125),
  maxWidth: '50rem',
}));

export const StyledLink = styled('a')(({ theme }) => ({
  color: 'inherit',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export const LinkWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'inline-flex',
  justifyContent: 'center',
  marginTop: theme.spacings.unit(6.5),
}));

export const StyledMobileLink = styled('button')(({ theme }) => ({
  background: 'transparent',
  border: `${theme.spacings.unit(1 / 4)} solid ${theme.colors.primary}`,
  alignSelf: 'center',
  textAlign: 'center',
  padding: `${theme.spacings.unit(2.5)} ${theme.spacings.unit(5.75)}`,
  backgroundColor: theme.colors.primary,
  color: theme.colors.white,
  letterSpacing: theme.spacings.unit(0.075),
  fontSize: theme.fonts.utility.size(6),
  '&:hover': {
    background: theme.colors.primary,
    border: `${theme.spacings.unit(1 / 4)} solid ${theme.colors.primary}`,
    color: theme.colors.white,
    cursor: 'pointer',
  },
}));

export const RepeaterWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacings.unit(12),
  '.MuiGrid-item:nth-of-type(odd) > div': {
    backgroundColor: theme.colors.tertiaryBackground,
  },
  [theme.breakpointsMediaQuery.down('md')]: {
    '.MuiGrid-item': {
      marginBottom: theme.spacings.unit(4),
    },
  },
}));

export const ImageRowContainer = styled('div')(({ theme }) => ({
  width: theme.spacings.unit(6),
  height: theme.spacings.unit(6),
  position: 'relative',
  alignSelf: 'center',
}));

export const InfoWithImage = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacings.unit(1),
  alignItems: 'center',
}));
