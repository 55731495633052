import React, { useEffect, useState } from 'react';
import router from 'next/router';

// Components
import Markdown from '../markdown/Markdown';

// Utils
import { correctHeightProportion } from '../../utils/image';
import { getStrapiMedia } from '../../utils/media';
import clamp from 'clamp-js';
import { hexToRgb, rgbDataURL } from 'corporate-utils';

// Models
import {
  HeaderCoverResizeType,
  HeaderDescriptionPositionType,
} from '../../models/enum';
import {
  Button,
  ButtonWrapper,
  ContentDivision,
  CoverContainer,
  Description,
  ImageContainerDefaultStyled,
  ImageContainerStyled,
  TextWrapper,
  Title,
  TitleH2,
  Wrapper,
} from './Banner.style';
import { useMediaQuery, useTheme } from '@mui/material';
import { DEFAULT_IMAGE } from '../../models/domain/const';
import ImageWithFallback from '../image-with-fallback/ImageWithFallback';
import { ButtonStyle } from '../button/Button';

const TITLE_MAX_NUMBER_OF_LINES = 5;
const DESCRIPTION_MAX_NUMBER_OF_LINES = 4;

export interface BannerProps {
  title?: string;
  description?: string;
  titleFontSize?: number;
  descriptionFontSize?: number;
  titleMobileFontSize?: number;
  descriptionMobileFontSize?: number;
  titleFontWeight?: number;
  titleMobileFontWeight?: number;
  descriptionFontWeight?: number;
  descriptionMobileFontWeight?: number;
  image?: any;
  button?: {
    text?: string;
    textFontSize?: number;
    textMobileFontSize?: number;
    textMobileFontWeight?: number;
    textFontWeight?: number;
    url?: string;
    type?: 'primary' | 'secondary';
  };
  overlayColor?: string;
  overlayColorMobile?: string;
  breadCrumb?: {
    links: {
      href: string;
      name: string;
      disabled?: boolean;
      color?: string;
    }[];
    pageActive: {
      name: string;
      color?: string;
    };
  };
  className?: string;
  fixedHeight?: boolean;
  fixedHeightForSmallScreen?: string;
  id?: string | number;
  textLineLimit?: boolean;
  fullContentHeight?: boolean;
  forceHideDescription?: boolean;
  forceHideButton?: boolean;
  forceHideBreadcrumb?: boolean;
  headerDescriptionPosition?: 'default' | 'under' | 'hidden';
  forceHideTitle?: boolean;
  forceDescriptionToBeWhite?: boolean;
  forceDescriptionToBeBlack?: boolean;
  headerCoverResize?: 'default_cover' | 'contain' | 'hidden';
  noLazyLoading?: boolean;
  centerText?: boolean;
  forceTextInsideImageAtSmallScreenForDefaultPosition?: boolean;
  useH1Title?: boolean;
  isPrerenderRequest: boolean;
}

const Banner: React.FC<BannerProps> = ({
  title,
  description,
  descriptionFontSize,
  descriptionMobileFontSize,
  descriptionFontWeight,
  descriptionMobileFontWeight,
  button,
  image,
  overlayColor,
  overlayColorMobile,
  id,
  textLineLimit = false,
  titleFontSize,
  titleMobileFontSize,
  titleFontWeight,
  titleMobileFontWeight,
  forceDescriptionToBeBlack,
  forceDescriptionToBeWhite,
  forceHideTitle,
  forceHideDescription,
  forceHideButton,
  forceHideBreadcrumb,
  headerDescriptionPosition = 'default',
  headerCoverResize = 'default_cover',
  noLazyLoading,
  centerText,
  forceTextInsideImageAtSmallScreenForDefaultPosition,
  fixedHeightForSmallScreen,
  useH1Title = true,
  isPrerenderRequest,
}: BannerProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpointsMediaQuery.up('sm'));
  const [, setRerender] = useState<number>(0); // Trick to force re-render

  useEffect(() => {
    setRerender(Date.now());
  }, []);

  const titleId = `bannerTitle__${id}`;
  const descriptionId = `bannerDescription__${id}`;

  const atLeastSomethingInDescriptionIsTrue = () => {
    return (
      !forceHideButton ||
      !forceHideDescription ||
      !forceHideTitle ||
      !forceHideBreadcrumb
    );
  };

  // sets the maximum number of lines for the Title and Description components rendered at small screens with headerDescriptionPosition equal to "DEFAULT"; used this instead of pure CSS for ensuring browser compatibility
  useEffect(() => {
    const descriptions = document.querySelectorAll(`.${descriptionId}_small`);
    descriptions.forEach((description) => {
      if (description)
        clamp(description as HTMLElement, {
          clamp: DESCRIPTION_MAX_NUMBER_OF_LINES,
        });
    });
  }, [descriptionId, isDesktop]);

  useEffect(() => {
    const titles = document.querySelectorAll(`.${titleId}_small`);
    titles.forEach((title) => {
      if (title)
        clamp(title as HTMLElement, {
          clamp: TITLE_MAX_NUMBER_OF_LINES,
        });
    });
  }, [titleId, isDesktop]);

  return (
    <>
      {/* Image and description */}
      <CoverContainer
        headerCoverResize={headerCoverResize}
        headerDescriptionPosition={headerDescriptionPosition}
        url={(!!image?.data?.attributes?.url).toString()}
        fixedHeightForSmallScreen={fixedHeightForSmallScreen}
        forceTextInsideImageAtSmallScreenForDefaultPosition={
          forceTextInsideImageAtSmallScreenForDefaultPosition
        }
      >
        {/* COVER IMAGE */}
        {/* When image is cover */}
        {image &&
          image.data &&
          image.data.attributes &&
          image?.data?.attributes?.url &&
          (HeaderCoverResizeType.DEFAULT_COVER ===
            (headerCoverResize as HeaderCoverResizeType) ||
            !headerCoverResize) && (
          <ImageContainerDefaultStyled
            overlayColor={overlayColor}
            overlayColorMobile={overlayColorMobile}
            forceTextInsideImageAtSmallScreenForDefaultPosition={
              forceTextInsideImageAtSmallScreenForDefaultPosition
            }
            headerDescriptionPosition={headerDescriptionPosition}
            headerCoverResize={headerCoverResize}
            url={(!!image?.data?.attributes?.url).toString()}
            fixedHeightForSmallScreen={fixedHeightForSmallScreen}
          >
            <ImageWithFallback
              layout="fill"
              objectFit="cover"
              height={
                correctHeightProportion(
                  image?.data?.attributes?.height,
                  image?.data?.attributes?.width
                ) || '600'
              }
              placeholder={noLazyLoading ? 'empty' : 'blur'}
              blurDataURL={
                overlayColor
                  ? rgbDataURL(
                    hexToRgb(overlayColor)?.[0],
                    hexToRgb(overlayColor)?.[1],
                    hexToRgb(overlayColor)?.[2]
                  )
                  : rgbDataURL(
                    hexToRgb('ffffff')?.[0],
                    hexToRgb('ffffff')?.[1],
                    hexToRgb('ffffff')?.[2]
                  )
              }
              src={getStrapiMedia(image?.data?.attributes?.url ?? '')}
              alt={image?.data?.attributes?.alternativeText || ''}
              priority={noLazyLoading}
              fallbackSrc={DEFAULT_IMAGE}
              isPrerenderRequest={isPrerenderRequest}
            />
          </ImageContainerDefaultStyled>
        )}

        {/* COVER IMAGE */}
        {/* When image is contain */}

        {image &&
          image?.data &&
          image?.data?.attributes &&
          image?.data?.attributes?.url &&
          (HeaderCoverResizeType.CONTAIN ===
            (headerCoverResize as HeaderCoverResizeType) ||
            !headerCoverResize) && (
          <ImageContainerStyled
            overlayColor={overlayColor}
            overlayColorMobile={overlayColorMobile}
            forceTextInsideImageAtSmallScreenForDefaultPosition={
              forceTextInsideImageAtSmallScreenForDefaultPosition
            }
            fixedHeightForSmallScreen={fixedHeightForSmallScreen}
            headerDescriptionPosition={headerDescriptionPosition}
          >
            <ImageWithFallback
              width={'1920'}
              height={
                correctHeightProportion(
                  image?.data?.attributes?.height,
                  image?.data?.attributes?.width
                ) || '600'
              }
              placeholder={noLazyLoading ? 'empty' : 'blur'}
              blurDataURL={
                overlayColor
                  ? rgbDataURL(
                    hexToRgb(overlayColor)?.[0],
                    hexToRgb(overlayColor)?.[1],
                    hexToRgb(overlayColor)?.[2]
                  )
                  : rgbDataURL(
                    hexToRgb('ffffff')?.[0],
                    hexToRgb('ffffff')?.[1],
                    hexToRgb('ffffff')?.[2]
                  )
              }
              src={getStrapiMedia(image?.data?.attributes?.url ?? '')}
              alt={image?.data?.attributes?.alternativeText || ''}
              priority={noLazyLoading}
              fallbackSrc={DEFAULT_IMAGE}
              isPrerenderRequest={isPrerenderRequest}
            />
          </ImageContainerStyled>
        )}

        {/* Description inside image */}
        {HeaderDescriptionPositionType.DEFAULT ===
          (headerDescriptionPosition as HeaderDescriptionPositionType) &&
        atLeastSomethingInDescriptionIsTrue() ? (
            <Wrapper
              forceTextInsideImageAtSmallScreenForDefaultPosition={
                forceTextInsideImageAtSmallScreenForDefaultPosition
              }
              lightContrast={
                !image?.data?.attributes?.url ||
              (headerCoverResize as HeaderCoverResizeType) ===
                HeaderCoverResizeType.HIDDEN
                  ? false
                  : true && !forceDescriptionToBeBlack
              }
              centerText={centerText}
            >
              <ContentDivision
                centerText={centerText}
                position={headerDescriptionPosition}
                forceTextInsideImageAtSmallScreenForDefaultPosition={
                  forceTextInsideImageAtSmallScreenForDefaultPosition
                }
              >
                {title &&
                !forceHideTitle &&
                (useH1Title ? (
                  <Title
                    fontSize={titleFontSize}
                    fontMobileSize={titleMobileFontSize}
                    fontWeight={titleFontWeight}
                    fontMobileWeight={titleMobileFontWeight}
                    position={headerDescriptionPosition}
                    forceTextInsideImageAtSmallScreenForDefaultPosition={
                      forceTextInsideImageAtSmallScreenForDefaultPosition
                    }
                  >
                    {isDesktop ? (
                      <Markdown
                        key={titleId}
                        id={titleId}
                        children={title}
                      ></Markdown>
                    ) : (
                      <Markdown
                        key={titleId + '_small'}
                        className={titleId + '_small'}
                        children={title}
                      ></Markdown>
                    )}
                  </Title>
                ) : (
                  <TitleH2
                    fontSize={titleFontSize}
                    fontMobileSize={titleMobileFontSize}
                    fontWeight={titleFontWeight}
                    fontMobileWeight={titleMobileFontWeight}
                    position={headerDescriptionPosition}
                    forceTextInsideImageAtSmallScreenForDefaultPosition={
                      forceTextInsideImageAtSmallScreenForDefaultPosition
                    }
                  >
                    {isDesktop ? (
                      <Markdown
                        key={titleId}
                        id={titleId}
                        children={title}
                      ></Markdown>
                    ) : (
                      <Markdown
                        key={titleId + '_small'}
                        className={titleId + '_small'}
                        children={title}
                      ></Markdown>
                    )}
                  </TitleH2>
                ))}

                {description && !forceHideDescription && (
                  <Description
                    textLineLimit={textLineLimit}
                    fontSize={descriptionFontSize}
                    fontMobileSize={descriptionMobileFontSize}
                    fontWeight={descriptionFontWeight}
                    fontMobileWeight={descriptionMobileFontWeight}
                    position={headerDescriptionPosition}
                    forceTextInsideImageAtSmallScreenForDefaultPosition={
                      forceTextInsideImageAtSmallScreenForDefaultPosition
                    }
                  >
                    {isDesktop ? (
                      <Markdown
                        key={descriptionId}
                        id={descriptionId}
                        children={description}
                      />
                    ) : (
                      <Markdown
                        key={descriptionId + '_small'}
                        className={descriptionId + '_small'}
                        children={description}
                      />
                    )}
                  </Description>
                )}
                {button && button.url && button.text && !forceHideButton && (
                  <ButtonWrapper>
                    <Button
                      onClick={(e: { preventDefault: () => void }): void => {
                        e.preventDefault();
                        button?.url && router.push(button?.url);
                      }}
                      text={button.text}
                      style={
                        button?.type
                          ? ButtonStyle[button.type]
                          : ButtonStyle.primary
                      }
                      fontSize={button?.textFontSize}
                      fontMobileSize={button?.textMobileFontSize}
                      fontWeight={button?.textFontWeight}
                      fontMobileWeight={button?.textMobileFontWeight}
                    ></Button>
                  </ButtonWrapper>
                )}
              </ContentDivision>
            </Wrapper>
          ) : (
            <></>
          )}
      </CoverContainer>

      {/* Description under the image */}
      {HeaderDescriptionPositionType.UNDER ===
        (headerDescriptionPosition as HeaderDescriptionPositionType) &&
      atLeastSomethingInDescriptionIsTrue() ? (
          <TextWrapper
            lightContrast={
              false ||
            (forceDescriptionToBeWhite &&
              (headerCoverResize as HeaderCoverResizeType) !==
                HeaderCoverResizeType.HIDDEN)
            }
          >
            <ContentDivision
              centerText={centerText}
              maxDimension={true}
              position={headerDescriptionPosition}
            >
              {HeaderDescriptionPositionType.UNDER ===
            (headerDescriptionPosition as HeaderDescriptionPositionType) ? (
                  <>
                    {title &&
                  !forceHideTitle &&
                  (useH1Title ? (
                    <Title
                      fontSize={titleFontSize}
                      fontMobileSize={titleMobileFontSize}
                      fontWeight={titleFontWeight}
                      fontMobileWeight={titleMobileFontWeight}
                      position={headerDescriptionPosition}
                      forceTextInsideImageAtSmallScreenForDefaultPosition={
                        forceTextInsideImageAtSmallScreenForDefaultPosition
                      }
                    >
                      <Markdown
                        key={titleId}
                        id={titleId}
                        children={title}
                      ></Markdown>
                    </Title>
                  ) : (
                    <TitleH2
                      fontSize={titleFontSize}
                      fontMobileSize={titleMobileFontSize}
                      fontWeight={titleFontWeight}
                      fontMobileWeight={titleMobileFontWeight}
                      position={headerDescriptionPosition}
                      forceTextInsideImageAtSmallScreenForDefaultPosition={
                        forceTextInsideImageAtSmallScreenForDefaultPosition
                      }
                    >
                      {isDesktop ? (
                        <Markdown
                          key={titleId}
                          id={titleId}
                          children={title}
                        ></Markdown>
                      ) : (
                        <Markdown
                          key={titleId + '_small'}
                          className={titleId + '_small'}
                          children={title}
                        ></Markdown>
                      )}
                    </TitleH2>
                  ))}
                    {description && !forceHideDescription && (
                      <Description
                        textLineLimit={textLineLimit}
                        fontSize={descriptionFontSize}
                        fontMobileSize={descriptionMobileFontSize}
                        fontWeight={descriptionFontWeight}
                        fontMobileWeight={descriptionMobileFontWeight}
                        position={headerDescriptionPosition}
                      >
                        <Markdown
                          key={descriptionId}
                          id={descriptionId}
                          children={description}
                        ></Markdown>
                      </Description>
                    )}
                    {button && button.url && button.text && !forceHideButton && (
                      <ButtonWrapper>
                        <Button
                          onClick={(e: { preventDefault: () => void }): void => {
                            e.preventDefault();
                            button?.url && router.push(button?.url);
                          }}
                          text={button.text}
                          style={
                            button?.type
                              ? ButtonStyle[button.type]
                              : ButtonStyle.primary
                          }
                          fontSize={button?.textFontSize}
                          fontMobileSize={button?.textMobileFontSize}
                          fontWeight={button?.textFontWeight}
                          fontMobileWeight={button?.textMobileFontWeight}
                        >
                          {button.text}
                        </Button>
                      </ButtonWrapper>
                    )}
                  </>
                ) : (
                  <></>
                )}
            </ContentDivision>
          </TextWrapper>
        ) : (
          <></>
        )}
    </>
  );
};

export default Banner;
