import * as React from 'react';
import { Pagination as PaginationMui } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';

interface IProps {
  count: number;
  page: number;
  onChangePage?: (page: number) => void;
  renderItem?: (item: any) => React.ReactNode;
}

const Pagination: React.FunctionComponent<IProps> = (props: IProps) => {
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (props.onChangePage) props.onChangePage(value);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpointsMediaQuery.down('sm'));
  const [, setRerender] = React.useState<number>(0); // Trick to force re-render

  React.useEffect(() => {
    setRerender(Date.now());
  }, []);

  return props?.renderItem ? (
    <PaginationMui
      count={props.count}
      page={props.page || 1}
      onChange={handleChange}
      renderItem={props.renderItem}
      variant="outlined"
      siblingCount={typeof window !== 'undefined' && isMobile ? 0 : 1}
      size={isMobile ? 'small' : 'medium'}
    />
  ) : (
    <PaginationMui
      count={props.count}
      page={props.page || 1}
      onChange={handleChange}
      variant="outlined"
      siblingCount={typeof window !== 'undefined' && isMobile ? 0 : 1}
      size={typeof window !== 'undefined' && isMobile ? 'small' : 'medium'}
    />
  );
};

export default Pagination;
