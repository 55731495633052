import React from 'react';
import { FieldProps } from 'formik';
import { FormControlProps, InputProps } from '@mui/material';
import { ArrowDown } from '../../../assets';
import {
  SelectLabelStyled,
  SelectSectionStyled,
  SelectStyled,
} from './Select.style';
import { TextSize } from '../../../models/enum';

export enum SelectType {
  'primary',
  'secondary',
  'disabled',
  'contactFormModal',
  'contactFormPage',
  'workshop',
}

interface IOptions {
  label: string;
  value: string;
  disabled?: boolean;
}

export interface SelectProps extends FormControlProps, FieldProps {
  label: string;
  options: IOptions[];
  inputProps: InputProps['inputProps'];
  multiple?: boolean;
  onChangeValue?(e: React.ChangeEvent<any>): void;
  selectType: string;
  Icon?: React.ReactElement;
  upcase?: boolean;
  placeholder?: string;
  fullWidth?: boolean;
  textSize?: TextSize;
  isPlaceholderValue?: boolean;
  section: string;
  disabled?: boolean;
}

const Select: React.FC<SelectProps> = ({
  form,
  field,
  label,
  options,
  inputProps,
  multiple = false,
  onChangeValue,
  selectType,
  Icon,
  upcase = false,
  placeholder,
  fullWidth = false,
  textSize,
  isPlaceholderValue,
  section,
  disabled,
  ...props
}: SelectProps) => {
  const id = `${section}_${field.name}`;
  const value = !field.value
    ? !multiple
      ? ''
      : []
    : !multiple
      ? field.value.toString()
      : field.value;

  return (
    <SelectSectionStyled {...props}>
      {label && (
        <SelectLabelStyled selectType={selectType} htmlFor={id}>
          {label}
        </SelectLabelStyled>
      )}
      <SelectStyled
        inputProps={inputProps}
        textSize={textSize}
        label={placeholder}
        fullWidth={fullWidth}
        upcase={upcase ? 'true' : 'false'}
        selectType={selectType}
        IconComponent={Icon ? Icon : ArrowDown}
        disableUnderline={true}
        id={id}
        name={field.name}
        value={value}
        disabled={disabled}
        isPlaceholderValue={isPlaceholderValue}
        onChange={(e: React.ChangeEvent<any>): void => {
          const valueOnChange =
            typeof e.target.value === 'string'
              ? e.target.value
              : multiple
                ? e.target.value
                : '';
          form.setFieldValue(field.name, valueOnChange);
          onChangeValue && onChangeValue(e);
        }}
      >
        {placeholder && (
          <option value={''} disabled hidden>
            {placeholder}
          </option>
        )}
        {options?.map((item, i) => (
          <option key={i} value={item.value}>
            {item.label}
          </option>
        ))}
      </SelectStyled>
    </SelectSectionStyled>
  );
};

export default Select;
