import { styled } from '@mui/material';
import { TableCellMUI } from '../Table.style';

export const TableHeaderCell = styled(
  (props: any) => (
    <TableCellMUI className={props?.className} {...props}>
      {props?.children}
    </TableCellMUI>
  ),
  {}
)(({ theme, className, children, ...props }) => {
  return {
    color: theme.colors.primary,
    fontSize: theme.fonts.utility.size(6),
    fontFamily: theme.fonts.default.semiBold.fontFamily,
    fontWeight: theme.fonts.default.semiBold.fontWeight,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: theme.tables.minWidthCell,
    padding: `${theme.fonts.utility.size(8)} ${theme.fonts.utility.size(
      0
    )} ${theme.fonts.utility.size(0)} ${theme.fonts.utility.size(8)}`,
    borderBottom: 0,
  };
});
