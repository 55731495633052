import React, { useState } from 'react';

import TableBodyRowCell from '../table-body-row-cell/TableBodyRowCell';
import TableBodyRowCollapse from '../table-body-row-collapse/TableBodyRowCollapse';
import TableBodyRow from '../table-body-row/TableBodyRow';
import TableIconCollapse from '../table-icon-collapse/TableIconCollapse';
import Table from '../Table';

import {
  TableBodyMUI,
  TableBodyNested,
  TableHeaderCellNested,
  TableHeaderNested,
  TableBodyEmptyMessage,
} from './TableBody.style';

const TableBody = ({
  collapse,
  children,
  data,
  className,
}: {
  className?: string;
  collapse?: boolean;
  children?: any;
  data?: {
    value?: string[];
    collapse?: {
      headers: string[];
      rows: {
        value: string[];
      }[];
      emptyResult?: string;
    };
  }[];
}) => {
  const [open, setOpen] = useState<{
    [key: number]: boolean;
  }>({});

  return (
    <TableBodyMUI className={className}>
      {children || (
        <>
          {data?.map((row: any, index: number) => {
            return (
              <React.Fragment key={index}>
                <TableBodyRow open={open[index]}>
                  {collapse ? (
                    <TableBodyRowCell size={'icon'}>
                      <TableIconCollapse
                        onClick={() => {
                          setOpen({
                            ...open,
                            [index]: !open[index],
                          });
                        }}
                        open={open[index]}
                      ></TableIconCollapse>
                      {/* better value */}
                    </TableBodyRowCell>
                  ) : (
                    <></>
                  )}
                  {row.value?.map((title: string, indexRow: number) => {
                    return (
                      <TableBodyRowCell key={indexRow}>
                        {title}
                      </TableBodyRowCell>
                    );
                  })}
                </TableBodyRow>
                {collapse ? (
                  <TableBodyRowCollapse
                    open={open[index]}
                    span={~~(row.value.length + 1)}
                  >
                    {row.collapse &&
                    row.collapse.headers &&
                    row.collapse.rows &&
                    row.collapse.rows.length > 0 ? (
                        <Table collapse={false}>
                          <TableHeaderNested
                            data={row.collapse.headers}
                            cell={TableHeaderCellNested}
                          ></TableHeaderNested>
                          <TableBodyNested
                            data={row.collapse.rows}
                          ></TableBodyNested>
                        </Table>
                      ) : row.collapse.emptyResult ? (
                        <TableBodyEmptyMessage>
                          {row.collapse.emptyResult}
                        </TableBodyEmptyMessage>
                      ) : (
                        <></>
                      )}
                  </TableBodyRowCollapse>
                ) : (
                  <></>
                )}
              </React.Fragment>
            );
          })}
        </>
      )}
    </TableBodyMUI>
  );
};

export default TableBody;
