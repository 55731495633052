import { styled } from '@mui/material';
import { IconButton } from '@mui/material';

export const IconButtonMUI = styled(
  (props: any) => (
    <IconButton className={props?.className} {...props}>
      {props?.children}
    </IconButton>
  ),
  {}
)(({ theme, className, children, ...props }) => {
  return {
    // color: ${({ theme }): string => theme.colors.tableIcon};
  };
});
