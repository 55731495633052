import { useContext } from 'react';
import { Action, HistoryState } from '../models/interface';
import { historyContext } from '../store';

export const useHistory = (): {
  state: HistoryState;
  dispatch: (action: Action) => void;
} => {
  const context = useContext(historyContext);
  if (context === undefined) {
    throw new Error('useHistory must be used within a HistoryProvider');
  }
  return context;
};
