import {
  fromPattenStringToObject,
  internationalizationToLanguages,
} from 'corporate-utils';
import {
  defaultInternationalization,
  defaultLanguage,
  priorityInternationalization,
} from 'corporate-types';
import { Product } from '../models/domain/interfaces';

export function imageMapper(
  productDwh: Product,
  userLanguage: string
): Product {
  const coverObj = fromPattenStringToObject(productDwh?.cover || '');

  let coverLink = '';

  if (Object.keys(coverObj).length > 0) {
    coverLink =
      coverObj[userLanguage.toUpperCase()] ||
      coverObj[
        (
          internationalizationToLanguages(
            priorityInternationalization[0] || defaultInternationalization,
            'packages/corporate-ui/src/utils/products.ts A'
          ) || defaultLanguage
        ).toUpperCase()
      ] ||
      coverObj[
        (
          internationalizationToLanguages(
            priorityInternationalization[1] || defaultInternationalization,
            'packages/corporate-ui/src/utils/products.ts B'
          ) || defaultLanguage
        ).toUpperCase()
      ];
  } else {
    coverLink = productDwh?.cover || '';
  }
  return {
    ...productDwh,
    cover: coverLink,
  };
}
