import { styled, Typography, Breadcrumbs, Link } from '@mui/material';
import { Companies } from '../../models/domain/enum';
import { getCompany } from '../../utils/company';

const getLinkStyles = (company: Companies, theme: any) => {
  switch (company) {
    case Companies.SIRMAN:
      return {
        fontWeight: 'bold',
        '&:hover': {
          textDecoration: 'underline',
          textUnderlineOffset: theme.spacings.unit(1),
        },
      };
    case Companies.FELSINEA:
      return {
        fontWeight: 600,
        textDecoration: 'underline',
        textUnderlineOffset: theme.spacings.unit(1),
        lineHeight: '160%',
        marginRight: theme.spacings.unit(0.5),
      };
  }
};

export const getTypographyStyles = (company: Companies, theme: any) => {
  switch (company) {
    case Companies.SIRMAN:
      return {};
    case Companies.FELSINEA:
      return {
        marginLeft: theme.spacings.unit(0.5),
      };
  }
};

export const BreadCrumbsStyled = styled(Breadcrumbs, {
  shouldForwardProp: (prop) =>
    !['color', 'lightContrast'].includes(prop as string),
})(({ theme, color, lightContrast }) => ({
  color: color ? theme.colors[color as string] : theme.colors.primaryText,
  [theme.breakpointsMediaQuery.up('sm')]: {
    color: color
      ? theme.colors[color as string]
      : lightContrast
        ? theme.colors.primaryText
        : theme.colors.white,
  },
}));

export const LinkStyled: any = styled(Link, {
  shouldForwardProp: (prop) =>
    !['color', 'lightContrast'].includes(prop as string),
})(({ theme, color, lightContrast }) => ({
  color: color ? theme.colors[color as string] : theme.colors.primaryText,
  fontSize: theme.fonts.utility.size(7),
  '&[disabled]': {
    cursor: 'default',
  },
  [theme.breakpointsMediaQuery.up('sm')]: {
    color: color
      ? theme.colors[color as string]
      : lightContrast
        ? theme.colors.primaryText
        : theme.colors.white,
  },
  ...getLinkStyles(getCompany(), theme),
}));

export const TypographyStyled = styled(Typography, {
  shouldForwardProp: (prop) =>
    !['color', 'lightContrast'].includes(prop as string),
})(({ theme, color, lightContrast }) => ({
  fontSize: theme.fonts.utility.size(7),
  color: color ? theme.colors[color as string] : theme.colors.primaryText,
  [theme.breakpointsMediaQuery.up('sm')]: {
    color: color
      ? theme.colors[color as string]
      : lightContrast
        ? theme.colors.primaryText
        : theme.colors.white,
  },
}));
