import Link from 'next/link';
import { AStyled } from './WrapComponentToLink.style';

interface Props {
  href?: string;
  children: any;
  onClick?: any;
}

export const WrapComponentToLink: React.FC<Props> = ({
  href,
  children,
}: Props) => {
  return href ? (
    <Link href={href} passHref prefetch={false}>
      <AStyled>{children}</AStyled>
    </Link>
  ) : (
    <>{children}</>
  );
};
