import { styled } from '@mui/material';
import { MobilePaddingLessContentWrapper } from '../../../styles/shared';

export const StyledContentWrapper = styled(MobilePaddingLessContentWrapper)(
  ({ theme }) => ({
    [theme.breakpointsMediaQuery.up('md')]: {
      padding: `0 ${theme.spacings.unit(26)}`,
    },
  })
);

export const CarouselNewsEventsWrapper = styled('div')(({ theme }) => ({
  margin: '0',
}));
