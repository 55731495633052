import * as React from 'react';
import { SelectType } from '../../models/enum';
import { SelectStyled } from './PageSelect.style';

interface IProps {
  currentValue: number;
  options?: {
    label: number;
    value: number;
  }[];
  type?: string;
  textSize?: string;
  onChangePageSize: (val: number) => void;
}

const PageSelect: React.FunctionComponent<IProps> = (props: IProps) => {
  const handleChange = (value: number) => {
    props.onChangePageSize(value);
  };

  return (
    <SelectStyled
      currentValue={~~props.currentValue}
      options={
        props.options || [
          {
            label: 12,
            value: 12,
          },
          {
            label: 24,
            value: 24,
          },
          {
            label: 48,
            value: 48,
          },
        ]
      }
      onChange={handleChange}
      type={SelectType.secondary}
      textSize={props.textSize || 'small'}
    />
  );
};

export default PageSelect;
