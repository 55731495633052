import { styled } from '@mui/material';
import { StyledLink } from '../../../styles/shared';

export const CardStyled = styled('div', {
  shouldForwardProp: (prop) => !['hasImage'].includes(prop as string),
})(({ theme, hasImage }) => ({
  padding: `${theme.spacings.unit(4)} ${theme.spacings.unit(
    3
  )} ${theme.spacings.unit(6.3425)}`,
  backgroundColor: theme.tables.evenRowBackgroundColor,
  color: theme.colors.primaryText,
  maxWidth: theme.spacings.unit(85.5),
  minHeight: hasImage ? theme.spacings.unit(110) : theme.spacings.unit(85.5),
  cursor: 'pointer',
}));

export const Website = styled('h2')(({ theme }) => ({
  margin: `0 0 ${theme.spacings.unit(5)}`,
  fontSize: theme.fonts.utility.size(10),
  letterSpacing: theme.spacings.unit(0.125),
}));

export const FieldContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  margin: `${theme.spacings.unit(3.75)} 0`,
}));

export const FieldHeader = styled('h3')(({ theme }) => ({
  fontSize: theme.fonts.utility.size(6),
  color: theme.colors.primary,
  letterSpacing: theme.spacings.unit(0.075),
  margin: 0,
}));

export const FieldText = styled('p')(({ theme }) => ({
  fontSize: theme.fonts.utility.size(10),
  color: theme.colors.primaryText,
  letterSpacing: theme.spacings.unit(0.125),
  margin: `${theme.spacings.unit(1.75)} 0 0`,
}));

export const StyledLinkMobile = styled('a')(({ theme }) => ({
  color: theme.colors.primaryText,
}));
