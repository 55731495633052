export const normalizeAttributesNewsEvents = (
  data: any[],
  type: string,
  translations: any,
  newsAndEventsMerged: boolean,
  locale: string
) => {
  return (data ?? []).map?.(({ id, attributes }: any) => ({
    id,
    attributes: {
      ...attributes,
      url: `/${locale}/${
        newsAndEventsMerged
          ? translations?.urls?.newsAndEvents
          : type == 'news'
            ? translations?.urls?.newsEntryPage
            : translations?.urls?.events
      }/${
        type == 'news' ? translations?.urls?.news : translations?.urls?.event
      }/${attributes?.slug}`,
    },
  }));
};

export const normalizeAttributesNewsEventsSliders = (
  data: any[],
  type: string,
  translations: any,
  newsAndEventsMerged: boolean,
  locale: string
) => {
  return (data ?? []).map?.(({ id, attributes }: any) => {
    return {
      id,
      attributes: {
        category: attributes?.category,
        title: attributes?.title,
        description: attributes?.description,
        button: attributes?.button,
        mobileThumbnail: {
          data: {
            attributes: {
              url: attributes?.mobileThumbnail?.data?.attributes?.url,
              alternativeText:
                attributes?.mobileThumbnail?.data?.attributes?.alternativeText,
            },
          },
        },
        thumbnail: {
          data: {
            attributes: {
              url: attributes?.thumbnail?.data?.attributes?.url,
              alternativeText:
                attributes?.thumbnail?.data?.attributes?.alternativeText,
            },
          },
        },
        overlayColor: attributes?.overlayColor,
        coverTextColor: attributes?.coverTextColor,
        date: attributes?.date,
        startDate: attributes?.startDate,
        endDate: attributes?.endDate,
        url: `/${locale}/${
          newsAndEventsMerged
            ? translations?.urls?.newsAndEvents
            : type == 'news'
              ? translations?.urls?.newsEntryPage
              : translations?.urls?.events
        }/${
          type == 'news' ? translations?.urls?.news : translations?.urls?.event
        }/${attributes?.slug}`,
      },
    };
  });
};
