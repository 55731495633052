import { styled } from '@mui/material';
import { mergePersonalization } from '../../../utils/style';

export const CardContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '50%',
  flex: `0 0 calc(33% - ${theme.spacings.unit(8)})`,
  alignItems: 'stretch',
  flexGrow: 1,
  [theme.breakpointsMediaQuery.down('lg')]: {
    flex: `0 0 calc(50% - ${theme.spacings.unit(8)})`,
  },
  [theme.breakpointsMediaQuery.down('sm')]: {
    maxWidth: '100%',
  },
}));

export const CardImageStyled = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '56%',
  width: 'auto',
  minHeight: theme.spacings.unit(62),
}));

export const CardTextWrapper = styled('div')(
  ({ theme, backgroundColor, personalization }) =>
    mergePersonalization(
      theme,
      {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacings.unit(5),
        backgroundColor: backgroundColor ? backgroundColor : theme.colors.black,
        flexGrow: 1,
      },
      personalization
    )
);

export const MainTitleWrapper = styled('div')(({ theme, personalization }) =>
  mergePersonalization(
    theme,
    {
      color: theme.colors.mainTitleWorkshopText,
      marginBottom: theme.spacings.unit(3),
      fontSize: theme.fonts.utility.size(6),
      fontWeight: 900,
      lineHeight: '120%',
      letterSpacing: theme.spacings.unit(0.48),
      textTransform: 'uppercase',
    },
    personalization
  )
);

export const TitleWrapper = styled('div')(({ theme, personalization }) =>
  mergePersonalization(
    theme,
    {
      color: theme.colors.white,
      fontSize: theme.fonts.utility.size(9),
      fontWeight: 600,
      lineHeight: '145%',
    },
    personalization
  )
);

export const DescriptionWrapper = styled('div')(({ theme, personalization }) =>
  mergePersonalization(
    theme,
    {
      color: theme.colors.secondaryTextWhite,
      fontSize: theme.fonts.utility.size(8),
      fontWeight: 400,
      lineHeight: '150%',
    },
    personalization
  )
);
