import { styled } from '@mui/material';

export const OuterWrapper = styled('div')(({ theme, color }) => ({
  backgroundColor: color || theme.colors.secondaryBackground,
}));

export const InnerWrapper = styled('div')(({ theme }) => ({
  maxWidth: theme.spacings.unit(490),
  margin: '0 auto',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacings.unit(12),
  padding: theme.spacings.unit(24),
  paddingTop: 0,
  [theme.breakpointsMediaQuery.down('md')]: {
    padding: 0,
    paddingBottom: theme.spacings.unit(14),
  },
  [theme.breakpointsMediaQuery.up('xl')]: {
    paddingLeft: theme.spacings.unit(20 / 4),
    paddingRight: theme.spacings.unit(20 / 4),
  },
}));

export const CardsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  height: 'auto',
  gap: `${theme.spacings.unit(8)}`,
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  alignItems: 'stretch',
  width: '100%',
  [theme.breakpointsMediaQuery.down('md')]: {
    flexDirection: 'column',
  },
}));

export const ButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpointsMediaQuery.down('md')]: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& > a': {
    borderRadius: theme.spacings.unit(0.25),
    padding: `${theme.spacings.unit(3.75)} ${theme.spacings.unit(5)}`,
    fontSize: theme.fonts.utility.size(7),
    fontWeight: 700,
    lineHeight: '125%',
    letterSpacing: theme.spacings.unit(0.49),
  },
}));
