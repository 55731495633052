import { styled } from '@mui/material';
import Table, {
  TableBodyRow,
  TableBodyRowCell,
} from '../../../components/table/Table';

export const FlexContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpointsMediaQuery.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'right',
    margin: `${theme.spacings.unit(2)} 0`,
  },
}));

export const TableStyled = styled((props: any) => (
  <Table className={props.className} {...props} />
))(({ theme }) => ({
  margin: `${theme.spacings.unit(4)} 0`,
}));

export const CellStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '& > span': {
    color: theme.colors.primary,
    fontSize: theme.fonts.utility.size(6),
    marginBottom: theme.spacings.unit(1),
    fontWeight: '600',
  },
}));

export const CenterCellStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: theme.spacings.unit(50 / 4),
  justifyContent: 'space-between',
  '& > span': {
    color: theme.colors.primary,
    fontSize: theme.fonts.utility.size(6),
    marginBottom: theme.spacings.unit(1),
    fontWeight: '600',
  },
}));

export const InfoWithImage = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacings.unit(1),
  alignItems: 'center',
}));

export const LinkWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'inline-flex',
  justifyContent: 'center',
  marginTop: theme.spacings.unit(6.5),
}));

export const StyledMobileLink = styled('button')(({ theme }) => ({
  background: 'transparent',
  border: `${theme.spacings.unit(1 / 4)} solid ${theme.colors.primary}`,
  alignSelf: 'center',
  textAlign: 'center',
  padding: `${theme.spacings.unit(2.5)} ${theme.spacings.unit(5.75)}`,
  backgroundColor: theme.colors.primary,
  color: theme.colors.white,
  letterSpacing: theme.spacings.unit(0.075),
  fontSize: theme.fonts.utility.size(6),
  '&:hover': {
    background: theme.colors.primary,
    border: `${theme.spacings.unit(1 / 4)} solid ${theme.colors.primary}`,
    color: theme.colors.white,
    cursor: 'pointer',
  },
}));

export const RepeaterWrapper = styled('div')(({ theme }) => ({
  margin: `${theme.spacings.unit(12)} 0 ${theme.spacings.unit(13)}`,
}));

export const ImageContainerStyled = styled('div')(() => ({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  zIndex: 0,
  margin: '16px',
}));

export const TableBodyRowCellStyled = styled((props: any) => (
  <TableBodyRowCell {...props} />
))(({ theme }) => ({
  position: 'relative',
  width: theme.spacings.unit(270 / 4),
  height: theme.spacings.unit(150 / 4),
  maxWidth: theme.spacings.unit(270 / 4),
}));

export const TableBodyRowStyled = styled((props: any) => (
  <TableBodyRow {...props} />
))(() => ({
  position: 'relative',
}));

export const ImageRowContainer = styled('div')(({ theme }) => ({
  width: theme.spacings.unit(6),
  height: theme.spacings.unit(6),
  position: 'relative',
  alignSelf: 'center',
}));

export const StyledLink = styled('a')(({ theme }) => ({
  color: 'inherit',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));
