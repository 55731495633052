import { NativeSelect, styled } from '@mui/material';
import { TextSize } from '../../../models/enum';
import { SelectType } from './Select';

export const SelectStyled = styled(
  (props: any) => <NativeSelect {...props} />,
  {
    shouldForwardProp: (prop) =>
      !['textSize', 'selectType', 'isPlaceholderValue'].includes(
        prop as string
      ),
  }
)(({ theme, selectType, disabled, textSize, isPlaceholderValue }) => {
  selectType = disabled ? SelectType.disabled : selectType;

  const disabledStyle = {
    color: theme.colors.textDisabled,
    fill: theme.colors.disabled,
    background: theme.colors.disabled,
    borderTop: '0px solid ' + theme.colors.white,
    borderBottom: '0px solid ' + theme.colors.white,
    borderLeft: '0px solid ' + theme.colors.white,
    borderRight: '0px solid ' + theme.colors.white,
  };

  const primaryStyle = {
    color: theme.colors.white,
    fill: theme.colors.white,
    background: theme.colors.primary,
    borderTop: '0px solid ' + theme.colors.white,
    borderBottom: '0px solid ' + theme.colors.white,
    borderLeft: '0px solid ' + theme.colors.white,
    borderRight: '0px solid ' + theme.colors.white,
  };

  const secondaryStyle = {
    color: theme.colors.primary,
    fill: theme.colors.primary,
    background: theme.colors.background,
    borderTop: '0px solid ' + theme.colors.white,
    borderBottom: '0px solid ' + theme.colors.white,
    borderLeft: '0px solid ' + theme.colors.white,
    borderRight: '0px solid ' + theme.colors.white,
  };

  const workshopStyle = {
    color: theme.colors.black,
    fill: theme.colors.black,
    background: theme.colors.white,
    borderTop: `${theme.spacings.unit(0)} solid ${theme.colors.black}`,
    borderBottom: `${theme.spacings.unit(1 / 4)} solid ${theme.colors.black}`,
    borderLeft: `${theme.spacings.unit(0)} solid ${theme.colors.black}`,
    borderRight: `${theme.spacings.unit(0)} solid ${theme.colors.black}`,
  };

  const contactFormModalStyle = {
    color: theme.colors.black,
    fill: theme.colors.black,
    background: theme.colors.white,
    borderTop: `${theme.spacings.unit(0)} solid ${theme.colors.black}`,
    borderBottom: `${theme.spacings.unit(1 / 4)} solid ${theme.colors.black}`,
    borderLeft: `${theme.spacings.unit(0)} solid ${theme.colors.black}`,
    borderRight: `${theme.spacings.unit(0)} solid ${theme.colors.black}`,
  };

  const contactFormPageStyle = {
    color: theme.colors.black,
    fill: theme.colors.black,
    background: theme.colors.white,
    borderTop: `${theme.spacings.unit(0)} solid ${theme.colors.black}`,
    borderBottom: `${theme.spacings.unit(1 / 4)} solid ${theme.colors.black}`,
    borderLeft: `${theme.spacings.unit(0)} solid ${theme.colors.black}`,
    borderRight: `${theme.spacings.unit(0)} solid ${theme.colors.black}`,
  };

  const smallStyle = {
    fontSize: '0.8rem',
  };

  const mediumStyle = {
    fontSize: '1rem',
  };

  const largeStyle = {
    fontSize: '1.2rem',
  };

  const contactFormFontSize = {
    fontSize: '0.9rem',
  };

  const workshopFontSize = {
    fontSize: '0.9rem',
  };

  const typeStyle = () => {
    switch (selectType) {
      case SelectType.primary:
      default:
        return primaryStyle;
      case SelectType.secondary:
        return secondaryStyle;
      case SelectType.disabled:
        return disabledStyle;
      case SelectType.contactFormModal:
        return contactFormModalStyle;
      case SelectType.contactFormPage:
        return contactFormPageStyle;
      case SelectType.workshop:
        return workshopStyle;
    }
  };

  const sizeStyle = () => {
    switch (textSize) {
      case TextSize.small:
        return smallStyle;
      case TextSize.medium:
      default:
        return mediumStyle;
      case TextSize.large:
        return largeStyle;
      case TextSize.contactForm:
        return contactFormFontSize;
      case TextSize.workshop:
        return workshopFontSize;
    }
  };

  return {
    borderTop: typeStyle().borderTop,
    borderRight: typeStyle().borderRight,
    borderBottom: typeStyle().borderBottom,
    borderLeft: typeStyle().borderLeft,
    '& select': {
      fontSize: sizeStyle().fontSize || '0.9em',
      borderRadius: 0,
      backgroundColor: typeStyle().background,
      borderColor: theme.colors.borderInput,
      padding: `4px ${theme.spacings.unit(2)} 6px ${theme.spacings.unit(2)}`,
      marginBottom: 0,
      color: isPlaceholderValue
        ? theme.colors.inputPlaceholder
        : typeStyle().color,

      '&:focus': {
        borderRadius: 0,
        backgroundColor: typeStyle().background,
      },
    },

    '& path': {
      fill: typeStyle().fill,
    },

    '& svg': {
      top: theme.spacings.unit(1),
      right: theme.spacings.unit(1),
    },
  };
});

export const SelectSectionStyled = styled('section')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
}));

export const SelectLabelStyled = styled('section', {
  shouldForwardProp: (prop) => !['selectType'].includes(prop as string),
})(({ theme, selectType }) => {
  const defaultStyle = {
    color: theme.colors.primaryText,
  };

  const contactFormModalStyle = {
    color: theme.colors.primaryText,
  };

  const getStyle = (): any => {
    switch (selectType) {
      default:
        return defaultStyle;
      case SelectType.contactFormModal:
        return contactFormModalStyle;
    }
  };

  return {
    fontSize: theme.fonts.utility.size(8),
    margin: `${theme.spacings.unit(1)} 0`,
    fontWeight: 600,
    ...getStyle(),
  };
});
