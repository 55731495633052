import { styled } from '@mui/material';
import {
  ArrowCircleLeftOutlinedIcon,
  ArrowCircleRightOutlinedIcon,
} from '../../assets';

export const Pagination = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  textAlign: 'right',
  justifyContent: 'flex-end',
}));

export const PageIndicator = styled('p')(({ theme }) => ({
  marginRight: '1rem',
  letterSpacing: '0.4px',
  color: theme.colors.primaryText,
  fontSize: theme.fonts.utility.size(6),
}));

export const Page = styled('span')(({ theme }) => ({
  fontWeight: 'bold',
  margin: 0,
}));

export const StyledArrowCircleLeftIcon = styled((props: any) => (
  <ArrowCircleLeftOutlinedIcon {...props} />
))(({ theme, disabled }) => ({
  cursor: disabled ? '' : 'pointer',
  color: disabled
    ? theme.colors.disabledNextPrevButton
    : theme.colors.primaryText,
  '&:hover': {
    color: disabled ? '' : theme.colors.primary,
  },
}));

export const StyledArrowCircleRightIcon = styled(ArrowCircleRightOutlinedIcon)(
  ({ theme, disabled }) => ({
    cursor: disabled ? '' : 'pointer',
    color: disabled
      ? theme.colors.disabledNextPrevButton
      : theme.colors.primaryText,
    '&:hover': {
      color: disabled ? '' : theme.colors.primary,
    },
  })
);
