export const normalizeAttributesWorkshops = (
  data: any[],
  translations: any, // TODO: missing type!
  locale: string
) => {
  return (data || [])?.map(({ id, attributes }: any) => ({
    id,
    attributes: {
      ...attributes,
      url: `/${locale}/${translations?.urls?.workshops}/${attributes?.slug}`,
    },
  }));
};

export const normalizeAttributesWorkshopsSliders = (
  data: any[],
  translations: any, // TODO: missing type!
  locale: string,
  isPrerenderRequest: boolean
): WorkshopNormalized[] => {
  return (data || [])?.map(({ id, attributes }: any) => {
    return {
      id,
      attributes: {
        overlayColor: attributes?.overlayColor,
        button: attributes?.button,
        description: attributes?.description,
        title: attributes?.title,
        category: attributes?.category,
        mobileThumbnail: {
          data: {
            attributes: {
              url: (attributes as any)?.mobileThumbnail?.data?.attributes?.url,
            },
          },
        },
        thumbnail: {
          data: {
            attributes: {
              url: (attributes as any)?.thumbnail?.data?.attributes?.url,
              alternativeText: (attributes as any)?.thumbnail?.data?.attributes
                ?.alternativeText,
            },
          },
        },
        url: `/${locale}/${translations?.urls?.workshops}/${attributes?.slug}`,
      },
      isPrerenderRequest,
    };
  });
};

export interface WorkshopNormalized {
  id: any;
  attributes: {
    overlayColor: any;
    button: any;
    description: any;
    title: any;
    category: any;
    mobileThumbnail: {
      data: {
        attributes: {
          url: any;
        };
      };
    };
    thumbnail: {
      data: {
        attributes: {
          url: any;
          alternativeText: any;
        };
      };
    };
    url: string;
  };
  isPrerenderRequest: boolean;
}
