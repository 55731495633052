import {
  styled,
  TableCell,
  TableCellProps,
  TableRow,
  TableRowProps,
} from '@mui/material';

export const TableCellMUI = styled(
  (props: any) => (
    <TableCell
      className={props?.className}
      size={['icon'].indexOf(props?.size) === -1 ? props?.size : 'medium'}
      {...props}
    >
      {props?.children}
    </TableCell>
  ),
  {
    shouldForwardProp: (prop) => prop !== 'size',
  }
)<TableCellProps>(({ theme, size, className, children, ...props }) => {
  return {
    padding: `${theme.spacings.unit(2)} 0 ${theme.spacings.unit(
      2
    )} ${theme.spacings.unit(3)}`,
    fontSize: theme.fonts.utility.size(7),
  };
});

export const TableRowMUI = styled(
  (props: any) => (
    <TableRow className={props?.className} {...props}>
      {props?.children}
    </TableRow>
  ),
  {
    shouldForwardProp: (prop) => prop !== 'showPadding',
  }
)<TableRowProps>(({ theme, showPadding, className, children, ...props }) => {
  return {};
});
