import React from 'react';
import { Grid } from '@mui/material';

interface IProps {
  cols: { xl?: number; lg?: number; md?: number; sm?: number; xs: number };
  data: any;
  props?: any;
  component: any;
  spacing?: number;
}

const Repeater: React.FC<IProps> = ({
  cols = { xs: 6 },
  data,
  component,
  props,
  spacing = 20,
}: IProps) => {
  return (
    <Grid container spacing={spacing}>
      {data?.map((data: any, i: number) => (
        <Grid item {...cols} key={i}>
          {React.createElement(component, { data, ...props })}
        </Grid>
      ))}
    </Grid>
  );
};

export default Repeater;
