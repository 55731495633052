import { FormControlLabel, Checkbox, styled, FormGroup } from '@mui/material';
import { TextSize } from '../../../models/enum';
import { CheckboxType } from './Checkbox';

export const CheckboxGroupStyled = styled(
  (props: any) => <FormGroup {...props} />,
  {
    shouldForwardProp: (prop) =>
      !['textSize', 'checkboxType', 'textSize'].includes(prop as string),
  }
)(({ theme, checkboxType, disabled, textSize }) => {
  checkboxType = disabled ? CheckboxType.disabled : checkboxType;

  const disabledStyle = {
    color: theme.colors.textDisabled,
    fill: theme.colors.disabled,
    background: theme.colors.disabled,
  };

  const primaryStyle = {
    color: theme.colors.white,
    fill: theme.colors.white,
    background: theme.colors.primary,
  };

  const secondaryStyle = {
    color: theme.colors.primary,
    fill: theme.colors.primary,
    background: theme.colors.background,
  };

  const workshopStyle = {
    color: theme.colors.primary,
    fill: theme.colors.primary,
    background: theme.colors.primary,
  };

  const contactFormModalStyle = {
    color: theme.colors.black,
    fill: theme.colors.black,
    background: theme.colors.white,
  };

  const contactFormPageStyle = {
    color: theme.colors.black,
    fill: theme.colors.black,
    background: theme.colors.white,
  };

  const smallStyle = {
    fontSize: '0.8rem',
  };

  const mediumStyle = {
    fontSize: '1rem',
  };

  const largeStyle = {
    fontSize: '1.2rem',
  };

  const contactFormFontSize = {
    fontSize: '0.9rem',
  };

  const workshopFontSize = {
    fontSize: '0.9rem',
  };

  const typeStyle = () => {
    switch (checkboxType) {
      case CheckboxType.primary:
      default:
        return primaryStyle;
      case CheckboxType.secondary:
        return secondaryStyle;
      case CheckboxType.disabled:
        return disabledStyle;
      case CheckboxType.contactFormModal:
        return contactFormModalStyle;
      case CheckboxType.contactFormPage:
        return contactFormPageStyle;
      case CheckboxType.workshop:
        return workshopStyle;
    }
  };

  const sizeStyle = () => {
    switch (textSize) {
      case TextSize.small:
        return smallStyle;
      case TextSize.medium:
      default:
        return mediumStyle;
      case TextSize.large:
        return largeStyle;
      case TextSize.contactForm:
        return contactFormFontSize;
      case TextSize.workshop:
        return workshopFontSize;
    }
  };

  return {
    '& path': {
      fill: typeStyle().fill,
    },

    '& svg': {
      top: theme.spacings.unit(1),
      right: theme.spacings.unit(1),
    },
  };
});

export const FormControlLabelStyled = styled((props: any) => (
  <FormControlLabel {...props} />
))(({ theme }) => {
  return {};
});

export const CheckboxStyled = styled((props: any) => <Checkbox {...props} />)(
  ({ theme }) => {
    return {};
  }
);

export const CheckboxSectionStyled = styled('section')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
}));

export const CheckboxLabelStyled = styled('section')(({ theme }) => ({
  fontSize: theme.fonts.utility.size(8),
  margin: `${theme.spacings.unit(1)} 0`,
  fontWeight: 600,
  color: theme.colors.primaryText,
}));
