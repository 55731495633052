import React, { useCallback } from 'react';
import { FieldProps } from 'formik';
import { FormControlProps } from '@mui/material';
import {
  FormControlLabelStyled,
  CheckboxGroupStyled,
  CheckboxLabelStyled,
  CheckboxSectionStyled,
  CheckboxStyled,
} from './Checkbox.style';

export enum CheckboxType {
  'primary',
  'secondary',
  'disabled',
  'contactFormModal',
  'contactFormPage',
  'workshop',
}

interface IOptions {
  label: string;
  value: string;
  disabled?: boolean;
}

export interface CheckboxProps extends FormControlProps, FieldProps {
  label: string;
  options: IOptions[];
  multiple?: boolean;
  onChange?(value: string | number | any): void;
  checkboxType: string;
  section: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
  form,
  field,
  label,
  options,
  onChange,
  checkboxType,
  section,
  ...props
}: CheckboxProps) => {
  const id = `${section}_${field?.name}`;

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const array = field?.value ? [...field.value] : [];
      const index = array.indexOf(e?.target?.value);
      if (index === -1) {
        array.push(e?.target?.value);
      } else {
        array.splice(index, 1);
      }
      form.setFieldValue(field?.name, array);
      onChange && onChange(e?.target?.value);
    },
    [field.name, field.value, form, onChange]
  );

  return (
    <CheckboxSectionStyled {...props}>
      {label && <CheckboxLabelStyled htmlFor={id}>{label}</CheckboxLabelStyled>}
      <CheckboxGroupStyled
        checkboxType={checkboxType}
        name={field?.name}
        value={field?.value}
        onChange={handleChange}
        onBlur={field?.onBlur}
      >
        {options?.map?.((item, i) => (
          <div key={i}>
            <FormControlLabelStyled
              key={`${field.name}-r-${i}`}
              value={item?.value}
              control={
                <CheckboxStyled
                  key={item?.value}
                  value={item?.value}
                  label={item?.label}
                  disabled={item?.disabled}
                  checked={field?.value?.includes?.(item?.value)}
                />
              }
              label={item?.label}
            />
          </div>
        ))}
      </CheckboxGroupStyled>
    </CheckboxSectionStyled>
  );
};

export default Checkbox;
