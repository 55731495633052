import { styled } from '@mui/material';
import { mergePersonalization } from '../../../utils/style';

export const CardWrapper = styled('div')(({ theme, cols }) => ({
  cursor: 'pointer',
  display: 'flex',
  width: `calc(25% - ${theme.spacings.unit(8)})`,
  minHeight: theme.spacings.unit(60),
  flexDirection: 'column',
  gap: theme.spacings.unit(4),
  [theme.breakpointsMediaQuery.up('xs')]: {
    width: cols ? `calc(100% / ${cols?.numberOfColumnsXS || 1})` : '100%',
  },
  [theme.breakpointsMediaQuery.up('sm')]: {
    width: cols ? `calc(100% / ${cols?.numberOfColumnsSM || 1})` : '100%',
  },
  [theme.breakpointsMediaQuery.up('md')]: {
    width: cols
      ? `calc(100% / ${cols?.numberOfColumnsMD || 3} - ${theme.spacings.unit(
        8
      )})`
      : '33%',
  },
  [theme.breakpointsMediaQuery.up('lg')]: {
    width: cols
      ? `calc(100% / ${cols?.numberOfColumnsLG || 4} - ${theme.spacings.unit(
        8
      )})`
      : '25%',
  },
  [theme.breakpointsMediaQuery.up('xl')]: {
    width: cols
      ? `calc(100% / ${cols?.numberOfColumnsXL || 4} - ${theme.spacings.unit(
        8
      )})`
      : '25%',
    minHeight: theme.spacings.unit(80),
  },
}));

export const CardImageStyled = styled('div')(({ theme }) => ({
  position: 'relative',
  height: theme.spacings.unit(200 / 4),
}));

export const CardTextStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacings.unit(2),
  [theme.breakpointsMediaQuery.down('md')]: {
    padding: `0 ${theme.spacings.unit(9)}`,
  },
}));

export const TitleWrapper = styled('div')(({ theme, personalization }) =>
  mergePersonalization(
    theme,
    {
      color: theme.colors.mainTitleTextGold,
      fontSize: theme.fonts.utility.size(6),
      fontWeight: 900,
      lineHeight: '120%',
      letterSpacing: theme.spacings.unit(0.48),
      textTransform: 'uppercase',
      textDecoration: 'none',
    },
    personalization
  )
);

export const LinkTitle = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  color: 'inherit',
}));

export const DescriptionWrapper = styled('div')(({ theme, personalization }) =>
  mergePersonalization(
    theme,
    {
      color: theme.colors.secondaryText,
      fontSize: theme.fonts.utility.size(9),
      fontWeight: 500,
      lineHeight: '145%',
    },
    personalization
  )
);
