import React, { useEffect, useMemo, useState } from 'react';
import compareDesc from 'date-fns/compareDesc';
import parse from 'date-fns/parse';
import { useMediaQuery, useTheme } from '@mui/material';

import CarouselNewsEvents from '../../../components/carousel/carousel-news-events/CarouselNewsEvents';
import SectionHeader from '../../../components/section-header/SectionHeader';
import {
  GlobalResponse,
  SingleStrapiImage,
  SliderConfigs,
  Slug,
} from '../../../models/domain/interfaces';
import { eventSliderConfigs } from '../../../utils/carousels';
import { normalizeAttributesNewsEventsSliders } from '../../../utils/event';
import { getEvents, getNews } from '../../../utils/api';
import { Pages } from '../../../models/domain/enum';

import {
  CarouselNewsEventsWrapper,
  StyledContentWrapper,
} from './SliderNewsEvents.style';

interface BaseCardProps {
  url?: any;
  title: string;
  description: string;
  text: string;
  category: string;
  thumbnail: SingleStrapiImage;
  mobileThumbnail?: SingleStrapiImage;
  coverImage: SingleStrapiImage;
  mobileCoverImage?: SingleStrapiImage;
  overlayColor: string;
  coverTextColor?: string;
  date: string;
  startDate?: string;
  endDate?: string;
  button?: {
    url: string;
    text: string;
    type: string;
    newTab: boolean;
  };
}

interface SliderNewsEventsProps {
  cards: {
    id: number;
    attributes: BaseCardProps;
  }[];
  mainTitle: string;
  title: string;
  mainTitleFontSize?: number;
  titleFontSize?: number;
  mainTitleMobileFontSize?: number;
  titleMobileFontSize?: number;
  titleMobileFontWeight?: number;
  titleFontWeight?: number;
  mainTitleMobileFontWeight?: number;
  mainTitleFontWeight?: number;
  size: any;
  showCarouselTitle?: boolean;
  sliderConfigs?: SliderConfigs;
  showButton?: boolean;
}

const SliderNewsEvents: React.FC<any> = ({
  data,
  translations,
  isPrerenderRequest,
}: {
  data: SliderNewsEventsProps;
  translations: any;
  isPrerenderRequest: boolean;
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpointsMediaQuery.up('md'));
  const [, setRerender] = useState<number>(0); // Trick to force re-render

  const cards = useMemo(
    () =>
      data &&
      data.cards &&
      Array.isArray(data.cards) &&
      data?.cards?.map(({ id, attributes }) => {
        const image =
          typeof window === 'undefined' || isDesktop
            ? attributes?.thumbnail
            : attributes?.mobileThumbnail?.data?.attributes?.url
              ? attributes?.mobileThumbnail
              : attributes?.thumbnail;

        return {
          category: attributes?.category,
          title: attributes?.title,
          description: attributes?.description,
          url: attributes?.url,
          button: attributes?.button,
          image: image?.data ? image : attributes?.thumbnail,
          overlayColor: attributes?.overlayColor,
          coverTextColor: attributes?.coverTextColor,
          date: attributes?.date,
          startDate: attributes?.startDate,
          endDate: attributes?.endDate,
          isLast: id === -1,
        };
      }),
    [data, isDesktop]
  );

  useEffect(() => {
    setRerender(Date.now());
  }, []);

  return (
    <StyledContentWrapper>
      <SectionHeader
        subtitle={data?.mainTitle ?? ''}
        title={data?.title ?? ''}
        titleFontSize={data?.titleFontSize}
        mainTitleFontSize={data?.mainTitleFontSize}
        titleMobileFontSize={data?.titleMobileFontSize}
        mainTitleMobileFontSize={data?.mainTitleMobileFontSize}
        mainTitleFontWeight={data?.mainTitleFontWeight}
        mainTitleMobileFontWeight={data?.mainTitleMobileFontWeight}
        titleFontWeight={data?.titleFontWeight}
        titleMobileFontWeight={data?.titleMobileFontWeight}
      ></SectionHeader>
      <CarouselNewsEventsWrapper>
        <CarouselNewsEvents
          carouselTitle={
            translations?.pages?.newsEvents?.newsEventsSlider
              ?.newsEventsCarouselTitle
          }
          showCarouselTitle={
            data?.showCarouselTitle || data?.sliderConfigs?.showCarouselTitle
          }
          cards={cards as any}
          goToNewsEventsLink={
            data?.showButton
              ? data?.sliderConfigs?.alternativeLink ||
                translations?.urls?.newsEntryPage
              : undefined
          }
          goToNewsEventsText={
            data?.sliderConfigs?.alternativeLinkText ||
            translations?.pages?.home?.newsEventsSlider?.goToNews
          }
          sliderConfigs={data?.sliderConfigs ?? eventSliderConfigs}
          isPrerenderRequest={isPrerenderRequest}
        />
      </CarouselNewsEventsWrapper>
    </StyledContentWrapper>
  );
};

export const getServerSideProps = async (
  section: any,
  translations: any,
  slugAnalyzed: Slug,
  globalLocale: GlobalResponse
) => {
  const news = (
    await getNews({
      locale: slugAnalyzed?.locale,
      pageSize: 6,
      sort: ['date:desc'],
      datesFormat: translations?.date?.sliderNewsEvents?.format,
    })
  ).data;
  const events = await getEvents({
    locale: slugAnalyzed?.locale,
    pageSize: 6,
    sort: ['publishedAt:desc'],
    datesFormat: translations?.date?.sliderNewsEvents?.format,
  });
  const sorted = [
    ...(normalizeAttributesNewsEventsSliders(
      news,
      'news',
      translations,
      globalLocale?.attributes?.newsAndEventsMerged || false,
      slugAnalyzed.locale
    ) || []),
    ...(normalizeAttributesNewsEventsSliders(
      events,
      'events',
      translations,
      globalLocale?.attributes?.newsAndEventsMerged || false,
      slugAnalyzed.locale
    ) || []),
  ].sort((a, b) => {
    const lhs = parse(
      a?.attributes?.date,
      translations?.date?.sliderNewsEvents?.format,
      new Date()
    );
    const rhs = parse(
      b?.attributes?.date,
      translations?.date?.sliderNewsEvents?.format,
      new Date()
    );
    return compareDesc(lhs, rhs);
  });
  if (!section?.sliderConfigs?.forceHideGoToArchive) {
    sorted.push({
      id: -1,
      attributes: {
        overlayColor: '#EFEFEF',
        button: {
          url:
            slugAnalyzed.type === Pages.EVENT
              ? section?.sliderConfigs?.alternativeLink ||
                `/${translations?.urls?.events}`
              : section?.sliderConfigs?.alternativeLink ||
                `/${translations?.urls?.newsEntryPage}`,
          text:
            section?.sliderConfigs?.alternativeLinkText ||
            translations?.pages?.home?.newsEventsSlider?.goToNews,
        },
      } as any,
    });
    section.showButton = true;
  } else {
    section.showButton = false;
  }
  section.cards = sorted;

  if (slugAnalyzed.type === Pages.HOME) {
    section.showCarouselTitle = false;
  }
};

export default SliderNewsEvents;
