import React from 'react';
import { useRouter } from 'next/router';
import { hexToRgb, rgbDataURL } from 'corporate-utils';

import { Category as CategoryModel } from '../../../models/domain/interfaces';
import { getStrapiMedia } from '../../../utils/media';
import { getAvailableStringFromCategoryByLocale } from '../../../utils/categories';
import {
  HistoryActions,
  HistoryPage,
  useHistory,
} from '../../../stores/history';
import {
  CardStyled,
  Category,
  Name,
  NameLinkStyled,
  ProductImgContainer,
} from './CardOtherProducts.style';
import Link from 'next/link';
import { DEFAULT_IMAGE } from '../../../models/domain/const';
import ImageWithFallback from '../../image-with-fallback/ImageWithFallback';

interface CardProps {
  data: {
    category: string;
    name: string;
    image: string;
    link: string;
    categoriesCalculatedInfo: CategoryModel[][];
  };
  translations: any;
  isPrerenderRequest: boolean;
}

const CardOtherProducts: React.FC<CardProps> = ({
  data,
  isPrerenderRequest,
}: CardProps) => {
  const { category, name, image, link, categoriesCalculatedInfo } = data || {};
  const router = useRouter();
  const { dispatch } = useHistory();

  const mainCategoriesCalculatedInfo = categoriesCalculatedInfo?.[0];
  const secondCategory =
    mainCategoriesCalculatedInfo?.[0] &&
    mainCategoriesCalculatedInfo?.[1] &&
    getAvailableStringFromCategoryByLocale(
      mainCategoriesCalculatedInfo?.[mainCategoriesCalculatedInfo?.[2] ? 1 : 0],
      router.locale,
      'packages/corporate-ui/src/components/cards/card-other-products/CardOtherProducts.tsx'
    );
  const thirdCategory =
    mainCategoriesCalculatedInfo?.[1] &&
    getAvailableStringFromCategoryByLocale(
      mainCategoriesCalculatedInfo?.[mainCategoriesCalculatedInfo?.[2] ? 2 : 1],
      router.locale,
      'packages/corporate-ui/src/components/cards/card-other-products/CardOtherProducts.tsx'
    );

  const handleClick = (e: any) => {
    e.preventDefault();
    dispatch({
      type: HistoryActions.SET,
      url: router.asPath,
      page: HistoryPage.CATALOG,
    });
    router.push(
      { pathname: link },
      { pathname: link },
      { locale: router.locale, shallow: false }
    );
  };

  return (
    <CardStyled
      onClick={(e: any) => {
        handleClick(e);
      }}
    >
      {category && <Category>{category}</Category>}
      <Link href={link} passHref>
        {name && (
          <NameLinkStyled>
            <Name>{name}</Name>
          </NameLinkStyled>
        )}
      </Link>
      {image && (
        <ProductImgContainer>
          <ImageWithFallback
            layout="fill"
            objectFit="cover"
            placeholder="blur"
            blurDataURL={rgbDataURL(
              hexToRgb('ffffff')?.[0],
              hexToRgb('ffffff')?.[1],
              hexToRgb('ffffff')?.[2]
            )}
            src={getStrapiMedia(image ?? '')}
            alt={`${secondCategory ?? ''} ${secondCategory ? '-' : ''} ${
              thirdCategory ?? ''
            } ${thirdCategory ? '-' : ''} ${name ?? ''} ${name ? '-' : ''} ${
              process?.env?.NEXT_PUBLIC_COMPANY_NAME ?? ''
            }`}
            fallbackSrc={DEFAULT_IMAGE}
            isPrerenderRequest={isPrerenderRequest}
          />
        </ProductImgContainer>
      )}
    </CardStyled>
  );
};

export default CardOtherProducts;
