import { styled } from '@mui/material';
import {
  HeaderCoverResizeType,
  HeaderDescriptionPositionType,
} from '../../models/enum';
import { StyledLink } from '../../styles/shared';
import ButtonComponent from '../button/Button';
import { getCompany } from '../../utils/company';
import { Companies } from '../../models/domain/enum';

export const ContentDivision = styled('div', {
  shouldForwardProp: (prop) =>
    ![
      'maxDimension',
      'centerText',
      'position',
      'forceTextInsideImageAtSmallScreenForDefaultPosition',
    ].includes(prop as string),
})(
  ({
    theme,
    maxDimension,
    centerText,
    position,
    forceTextInsideImageAtSmallScreenForDefaultPosition,
  }) => {
    return {
      height: '100%',
      display: 'flex',
      gap: theme.fonts.utility.size(8),
      flexDirection: 'column',
      textAlign: centerText ? 'center' : 'left',
      alignItems: centerText ? 'center' : 'flex-start',
      justifyContent: centerText ? 'center' : 'flex-start',
      maxWidth: maxDimension || centerText ? '100%' : '45%',
      [theme.breakpointsMediaQuery.down('sm')]: {
        maxWidth: 'inherit',
        alignItems:
          getCompany() === Companies.SIRMAN
            ? position === HeaderDescriptionPositionType.DEFAULT &&
              forceTextInsideImageAtSmallScreenForDefaultPosition
              ? 'center'
              : 'start'
            : 'center',
      },
    };
  }
);

export const ImageContainerStyled = styled('div', {
  shouldForwardProp: (prop) =>
    ![
      'fixedHeightForSmallScreen',
      'forceTextInsideImageAtSmallScreenForDefaultPosition',
      'headerDescriptionPosition',
      'overlayColor',
      'overlayColorMobile',
    ].includes(prop as string),
})(
  ({
    theme,
    fixedHeightForSmallScreen,
    forceTextInsideImageAtSmallScreenForDefaultPosition,
    headerDescriptionPosition,
    overlayColor,
    overlayColorMobile,
  }) => ({
    gridArea: '1 / 1 / 1 / 1',
    '> span': {
      height: '100% !important',
      zIndex: '-1',
    },
    boxShadow:
      (headerDescriptionPosition as HeaderDescriptionPositionType) !==
        HeaderDescriptionPositionType.HIDDEN && overlayColor
        ? `inset 0 0 0 3000px ${
          overlayColor.length === 7 ? overlayColor + '40' : overlayColor
        }`
        : 'none',
    img: {
      objectFit: 'cover',
    },
    [theme.breakpointsMediaQuery.down('sm')]: {
      boxShadow:
        (headerDescriptionPosition as HeaderDescriptionPositionType) !==
          HeaderDescriptionPositionType.HIDDEN && overlayColorMobile
          ? `inset 0 0 0 3000px ${
            overlayColorMobile.length === 7
              ? overlayColorMobile + '40'
              : overlayColorMobile
          }`
          : 'none',
      height:
        fixedHeightForSmallScreen &&
        forceTextInsideImageAtSmallScreenForDefaultPosition
          ? fixedHeightForSmallScreen
          : 'auto',
    },
  })
);

export const ImageContainerDefaultStyled = styled('div', {
  shouldForwardProp: (prop) =>
    ![
      'headerCoverResize',
      'url',
      'headerDescriptionPosition',
      'forceTextInsideImageAtSmallScreenForDefaultPosition',
      'fixedHeightForSmallScreen',
      'overlayColor',
      'overlayColorMobile',
    ].includes(prop as string),
})(
  ({
    theme,
    headerCoverResize,
    url,
    headerDescriptionPosition,
    forceTextInsideImageAtSmallScreenForDefaultPosition,
    fixedHeightForSmallScreen,
    overlayColor,
    overlayColorMobile,
  }) => ({
    boxShadow:
      (headerDescriptionPosition as HeaderDescriptionPositionType) !==
        HeaderDescriptionPositionType.HIDDEN && overlayColor
        ? `inset 0 0 0 3000px ${
          overlayColor.length === 7 ? overlayColor + '40' : overlayColor
        }`
        : 'none',
    '> span': {
      zIndex: '-1',
    },
    position:
      headerDescriptionPosition !== HeaderDescriptionPositionType.DEFAULT
        ? 'relative'
        : forceTextInsideImageAtSmallScreenForDefaultPosition
          ? 'unset' //edge case: content inside + fixed height image would cause overlapping
          : 'relative',
    display: 'block',
    [theme.breakpointsMediaQuery.down('sm')]: {
      boxShadow:
        (headerDescriptionPosition as HeaderDescriptionPositionType) !==
          HeaderDescriptionPositionType.HIDDEN && overlayColorMobile
          ? `inset 0 0 0 3000px ${
            overlayColorMobile.length === 7
              ? overlayColorMobile + '40'
              : overlayColorMobile
          }`
          : 'none',
      gridArea: forceTextInsideImageAtSmallScreenForDefaultPosition
        ? '1 / 1 / 1 / 1'
        : 'auto',
      height:
        fixedHeightForSmallScreen &&
        forceTextInsideImageAtSmallScreenForDefaultPosition
          ? fixedHeightForSmallScreen
          : 'auto',
      minHeight: (() => {
        if (
          headerDescriptionPosition === HeaderDescriptionPositionType.DEFAULT &&
          forceTextInsideImageAtSmallScreenForDefaultPosition
        )
          return 0;
        switch (headerCoverResize) {
          default:
          case HeaderCoverResizeType.DEFAULT_COVER:
            return url === 'true' ||
              !forceTextInsideImageAtSmallScreenForDefaultPosition
              ? '30vh'
              : 0;
          case HeaderCoverResizeType.CONTAIN:
            return 'auto';
          case HeaderCoverResizeType.HIDDEN:
            return 0;
        }
      })(),
    },
    [theme.breakpointsMediaQuery.up('sm')]: {
      maxHeight: 'none',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      display: 'flex',
      zIndex: -3,
    },
  })
);

export const CoverContainer = styled('div', {
  shouldForwardProp: (prop) =>
    ![
      'headerCoverResize',
      'headerDescriptionPosition',
      'url',
      'fixedHeightForSmallScreen',
      'forceTextInsideImageAtSmallScreenForDefaultPosition',
    ].includes(prop as string),
})(
  ({
    theme,
    headerCoverResize,
    headerDescriptionPosition,
    url,
    fixedHeightForSmallScreen,
    forceTextInsideImageAtSmallScreenForDefaultPosition,
  }) => ({
    position: 'relative',
    display: 'grid',
    height:
      headerDescriptionPosition === HeaderDescriptionPositionType.DEFAULT
        ? '100%'
        : 'auto',
    width: '100%',
    color: theme.colors.primaryText,
    '&:after': {
      opacity: 1,
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      zIndex: -1,
    },
    [theme.breakpointsMediaQuery.down('sm')]: {
      maxHeight:
        headerCoverResize !== HeaderCoverResizeType.HIDDEN &&
        fixedHeightForSmallScreen &&
        forceTextInsideImageAtSmallScreenForDefaultPosition
          ? fixedHeightForSmallScreen
          : 'none',
    },
    [theme.breakpointsMediaQuery.up('sm')]: {
      minHeight: (() => {
        if (
          headerDescriptionPosition === HeaderDescriptionPositionType.UNDER &&
          headerCoverResize === HeaderCoverResizeType.HIDDEN
        )
          return 0;
        switch (headerCoverResize) {
          default:
          case HeaderCoverResizeType.DEFAULT_COVER:
            return url === 'true' ? theme.spacings.unit(130) : 'auto';
          case HeaderCoverResizeType.CONTAIN:
            return (headerDescriptionPosition as HeaderDescriptionPositionType) ===
              HeaderDescriptionPositionType.UNDER
              ? 'auto'
              : '100%';
          case HeaderCoverResizeType.HIDDEN:
            return 0;
        }
      })(),
      color: theme.colors.white,
    },
    [theme.breakpointsMediaQuery.up('md')]: {
      height: (() => {
        if (
          headerDescriptionPosition === HeaderDescriptionPositionType.UNDER &&
          headerCoverResize === HeaderCoverResizeType.HIDDEN
        )
          return 0;
        switch (headerCoverResize) {
          default:
          case HeaderCoverResizeType.DEFAULT_COVER:
            return url === 'true' ? theme.spacings.unit(130) : 'auto';
          case HeaderCoverResizeType.CONTAIN:
            return (headerDescriptionPosition as HeaderDescriptionPositionType) ===
              HeaderDescriptionPositionType.UNDER
              ? 'auto'
              : '100%';
          case HeaderCoverResizeType.HIDDEN:
            return 'auto';
        }
      })(),
    },
    [theme.breakpointsMediaQuery.up('xl')]: {
      height: (() => {
        if (
          headerDescriptionPosition === HeaderDescriptionPositionType.UNDER &&
          headerCoverResize === HeaderCoverResizeType.HIDDEN
        )
          return 0;
        switch (headerCoverResize) {
          default:
          case HeaderCoverResizeType.DEFAULT_COVER:
            return url === 'true' ? theme.spacings.unit(130) : 'auto';
          case HeaderCoverResizeType.CONTAIN:
            return (headerDescriptionPosition as HeaderDescriptionPositionType) ===
              HeaderDescriptionPositionType.UNDER
              ? 'auto'
              : '100%';
          case HeaderCoverResizeType.HIDDEN:
            return 'auto';
        }
      })(),
    },
  })
);

export const Wrapper = styled('div', {
  shouldForwardProp: (prop) =>
    ![
      'lightContrast',
      'centerText',
      'forceTextInsideImageAtSmallScreenForDefaultPosition',
    ].includes(prop as string),
})(
  ({
    theme,
    lightContrast,
    centerText,
    forceTextInsideImageAtSmallScreenForDefaultPosition,
  }) => ({
    position: 'relative',
    color: lightContrast ? theme.colors.white : theme.colors.primaryText,
    gridArea: '1 / 1 / 1 / 1',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: forceTextInsideImageAtSmallScreenForDefaultPosition
      ? `
    ${theme.spacings.unit(16)} 
    ${theme.spacings.unit(6)} 
    ${theme.spacings.unit(16)} 
    ${theme.spacings.unit(6)}`
      : `
      ${theme.spacings.unit(4.5)} 
      ${theme.spacings.unit(9)} 
      ${theme.spacings.unit(2.25)} 
      ${theme.spacings.unit(9)}
    `,
    [theme.breakpointsMediaQuery.down('sm')]: {
      gridArea: forceTextInsideImageAtSmallScreenForDefaultPosition
        ? '1 / 1 / 1 / 1'
        : 'auto',
      color:
        lightContrast && forceTextInsideImageAtSmallScreenForDefaultPosition
          ? theme.colors.white
          : theme.colors.primaryText,
    },
    [theme.breakpointsMediaQuery.up('sm')]: {
      padding: `
      ${theme.spacings.unit(14)} 
      ${theme.spacings.unit(10)} 
      ${theme.spacings.unit(14)} 
      ${theme.spacings.unit(20)}
    `,
    },
    [theme.breakpointsMediaQuery.up('lg')]: {
      padding: centerText
        ? `
      ${theme.spacings.unit(20)} 
      26%
      ${theme.spacings.unit(20)} 
      26%
      `
        : `
      ${theme.spacings.unit(20)} 
      0
      ${theme.spacings.unit(20)} 
      ${theme.spacings.unit(28)}
    `,
    },
  })
);

export const Title = styled('h1', {
  shouldForwardProp: (prop) =>
    ![
      'fontSize',
      'fontMobileSize',
      'fontMobileWeight',
      'fontMobileWeight',
      'position',
      'forceTextInsideImageAtSmallScreenForDefaultPosition',
    ].includes(prop as string),
})(
  ({
    theme,
    fontSize,
    fontMobileSize,
    fontMobileWeight,
    fontWeight,
    position,
    forceTextInsideImageAtSmallScreenForDefaultPosition,
  }) => ({
    margin: 0,
    '&& div': {
      margin: 0,
      wordBreak: 'break-word',
      [theme.breakpointsMediaQuery.up('sm')]: {
        fontSize: fontSize
          ? `${fontSize * 0.5}px`
          : theme.fonts.utility.size((40 * 0.5) / 2),
        lineHeight: fontSize
          ? `${(fontSize + 6 * 0.5) * 0.5}px`
          : `${theme.fonts.utility.lineHeight(46 / 4)}`,
        fontWeight: fontWeight ? (fontWeight as number) : 600,
      },

      [theme.breakpointsMediaQuery.up('md')]: {
        fontSize: fontSize
          ? `${fontSize * 0.6}px`
          : theme.fonts.utility.size((40 * 0.6) / 2),
        lineHeight: fontSize
          ? `${(fontSize + 6 * 0.6) * 0.6}px`
          : `${theme.fonts.utility.lineHeight(46 / 4)}`,
      },

      [theme.breakpointsMediaQuery.up('lg')]: {
        fontSize: fontSize
          ? `${fontSize * 0.8}px`
          : theme.fonts.utility.size((40 * 0.8) / 2),
        lineHeight: fontSize
          ? `${(fontSize + 6 * 0.8) * 0.8}px`
          : `${theme.fonts.utility.lineHeight(46 / 4)}`,
      },

      [theme.breakpointsMediaQuery.up('xl')]: {
        fontSize: fontSize ? `${fontSize}px` : theme.fonts.utility.size(40 / 2),
        lineHeight: fontSize
          ? `${fontSize + 6}px`
          : `${theme.fonts.utility.lineHeight(46 / 4)}`,
      },

      [theme.breakpointsMediaQuery.down('sm')]: {
        fontSize: fontMobileSize
          ? `${fontMobileSize}px`
          : theme.fonts.utility.size(28 / 2),
        lineHeight: fontMobileSize
          ? `${fontMobileSize + 6}px`
          : `${theme.fonts.utility.lineHeight(34 / 4)}`,
        fontWeight: fontMobileWeight ? (fontMobileWeight as number) : 600,
        textAlign:
          forceTextInsideImageAtSmallScreenForDefaultPosition &&
          position === HeaderDescriptionPositionType.DEFAULT
            ? 'center'
            : 'left',

        // THE FOLLOWING CSS LINES HAVE BEEN COMMENTED OUT BECAUSE WE ARE USING A JS SCRIPT IN Banner.tsx TO SET THE MAX NUMBER OF LINES

        // overflow: 'hidden',
        // textOverflow: 'ellipsis',
        // WebkitBoxOrient: 'vertical',
        // display: '-webkit-box',
        // WebkitLineClamp:
        //   TITLE_MAX_NUMBER_OF_LINES /* number of lines to show */,
        // lineClamp: TITLE_MAX_NUMBER_OF_LINES,
        // maxHeight:
        //   fontMobileSize /* Fallback for browser that don't support line-clamp: this will set the max height to the correct number of rows bases on the font size */
        //     ? `${(fontMobileSize + 6) * TITLE_MAX_NUMBER_OF_LINES}px`
        //     : `${
        //       +(theme.fonts.utility.lineHeight(34 / 4) as string).slice(
        //         0,
        //         -2
        //       ) * TITLE_MAX_NUMBER_OF_LINES
        //     }px`,
      },
    },
  })
);

export const TitleH2 = styled('h2', {
  shouldForwardProp: (prop) =>
    ![
      'fontSize',
      'fontMobileSize',
      'fontMobileWeight',
      'fontMobileWeight',
      'position',
      'forceTextInsideImageAtSmallScreenForDefaultPosition',
    ].includes(prop as string),
})(
  ({
    theme,
    fontSize,
    fontMobileSize,
    fontMobileWeight,
    fontWeight,
    position,
    forceTextInsideImageAtSmallScreenForDefaultPosition,
  }) => ({
    margin: 0,
    '&& div': {
      margin: 0,
      wordBreak: 'break-word',
      [theme.breakpointsMediaQuery.up('sm')]: {
        fontSize: fontSize
          ? `${fontSize * 0.5}px`
          : theme.fonts.utility.size((40 * 0.5) / 2),
        lineHeight: fontSize
          ? `${(fontSize + 6 * 0.5) * 0.5}px`
          : `${theme.fonts.utility.lineHeight(46 / 4)}`,
        fontWeight: fontWeight ? (fontWeight as number) : 600,
      },

      [theme.breakpointsMediaQuery.up('md')]: {
        fontSize: fontSize
          ? `${fontSize * 0.6}px`
          : theme.fonts.utility.size((40 * 0.6) / 2),
        lineHeight: fontSize
          ? `${(fontSize + 6 * 0.6) * 0.6}px`
          : `${theme.fonts.utility.lineHeight(46 / 4)}`,
      },

      [theme.breakpointsMediaQuery.up('lg')]: {
        fontSize: fontSize
          ? `${fontSize * 0.8}px`
          : theme.fonts.utility.size((40 * 0.8) / 2),
        lineHeight: fontSize
          ? `${(fontSize + 6 * 0.8) * 0.8}px`
          : `${theme.fonts.utility.lineHeight(46 / 4)}`,
      },

      [theme.breakpointsMediaQuery.up('xl')]: {
        fontSize: fontSize ? `${fontSize}px` : theme.fonts.utility.size(40 / 2),
        lineHeight: fontSize
          ? `${fontSize + 6}px`
          : `${theme.fonts.utility.lineHeight(46 / 4)}`,
      },

      [theme.breakpointsMediaQuery.down('sm')]: {
        fontSize: fontMobileSize
          ? `${fontMobileSize}px`
          : theme.fonts.utility.size(28 / 2),
        lineHeight: fontMobileSize
          ? `${fontMobileSize + 6}px`
          : `${theme.fonts.utility.lineHeight(34 / 4)}`,
        fontWeight: fontMobileWeight ? (fontMobileWeight as number) : 600,
        textAlign:
          forceTextInsideImageAtSmallScreenForDefaultPosition &&
          position === HeaderDescriptionPositionType.DEFAULT
            ? 'center'
            : 'left',
      },
    },
  })
);

export const Description = styled('div', {
  shouldForwardProp: (prop) =>
    ![
      'textLineLimit',
      'fontSize',
      'fontMobileSize',
      'fontMobileWeight',
      'fontMobileWeight',
      'position',
      'forceTextInsideImageAtSmallScreenForDefaultPosition',
    ].includes(prop as string),
})(
  ({
    theme,
    textLineLimit,
    fontSize,
    fontMobileSize,
    fontMobileWeight,
    fontWeight,
    position,
    forceTextInsideImageAtSmallScreenForDefaultPosition,
  }) => ({
    '& > div': {
      maxWidth: theme.spacings.unit(600 / 4),
      fontSize: theme.fonts.utility.size(18 / 2),
      lineHeight: `${theme.fonts.utility.lineHeight(24 / 4)}`,
      fontWeight: fontWeight ? (fontWeight as number) : 'inherit',

      span: {
        display: 'inline-block',
        lineHeight: `${theme.fonts.utility.lineHeight(26 / 4)}`,
        ...(textLineLimit && {
          maxHeight: `calc(${theme.spacings.unit(30 / 4)} * 3)`,
          overflowY: 'hidden',
        }),
      },
      [theme.breakpointsMediaQuery.down('sm')]: {
        textAlign:
          forceTextInsideImageAtSmallScreenForDefaultPosition &&
          position === HeaderDescriptionPositionType.DEFAULT
            ? 'center'
            : 'left',
        fontSize: fontMobileSize
          ? `${fontMobileSize}px`
          : theme.fonts.utility.size(32 / 4),
        fontWeight: fontMobileWeight ? (fontMobileWeight as number) : 400,

        // THE FOLLOWING CSS LINES HAVE BEEN COMMENTED OUT BECAUSE WE ARE USING A JS SCRIPT IN Banner.tsx TO SET THE MAX NUMBER OF LINES

        // overflow: 'hidden',
        // textOverflow: 'ellipsis',
        // WebkitBoxOrient: 'vertical',
        // display: '-webkit-box',
        // WebkitLineClamp:
        //   DESCRIPTION_MAX_NUMBER_OF_LINES /* number of lines to show */,
        // lineClamp: DESCRIPTION_MAX_NUMBER_OF_LINES,
        // /* Fallback for browser that don't support line-clamp: this will set the max height to the correct number of rows bases on the font size */
        // maxHeight: `${
        //   +(theme.fonts.utility.lineHeight(24 / 4) as string).slice(0, -2) *
        //   DESCRIPTION_MAX_NUMBER_OF_LINES
        // }px`,
      },
      [theme.breakpointsMediaQuery.up('sm')]: {
        fontSize: fontSize ? `${fontSize}px` : theme.fonts.utility.size(18 / 2),
        lineHeight: fontSize
          ? `${fontSize + 6}px`
          : `${theme.fonts.utility.lineHeight(26 / 4)}`,
        fontWeight: fontWeight ? (fontWeight as number) : 'inherit',
      },
    },
  })
);

export const ButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'start',
  marginTop: theme.spacings.unit(16 / 4),
  [theme.breakpointsMediaQuery.up('md')]: {
    flexGrow: 0,
  },
}));

export const Button = styled(({ text, style, className, ...props }: any) => {
  return (
    <ButtonComponent
      text={text}
      styleBtn={style}
      className={className}
      {...props}
    ></ButtonComponent>
  );
})(({ theme, fontSize, fontMobileSize, fontMobileWeight, fontWeight }) => ({
  padding: theme.spacings.unit(4),
  alignSelf: 'center',
  textAlign: 'center',
  [theme.breakpointsMediaQuery.up('sm')]: {
    fontSize: fontSize ? `${fontSize}px` : theme.fonts.utility.size(18 / 2),
    lineHeight: fontSize
      ? `${fontSize + 6}px`
      : `${theme.fonts.utility.lineHeight(26 / 4)}`,
    fontWeight: fontWeight ? (fontWeight as number) : 600,
  },
  [theme.breakpointsMediaQuery.up('md')]: {
    alignSelf: 'start',
  },
  [theme.breakpointsMediaQuery.down('sm')]: {
    fontSize: fontMobileSize
      ? `${fontMobileSize}px`
      : theme.fonts.utility.size(18 / 2),
    lineHeight: fontMobileSize
      ? `${fontMobileSize + 6}px`
      : `${theme.fonts.utility.lineHeight(26 / 4)}`,
    fontWeight: fontMobileWeight ? (fontMobileWeight as number) : 600,
  },
}));

export const TextWrapper = styled('div', {
  shouldForwardProp: (prop) => !['lightContrast'].includes(prop as string),
})(({ theme, lightContrast }) => ({
  fontSize: theme.fonts.utility.size(7),
  letterSpacing: theme.spacings.unit(0.0875),
  maxWidth: theme.spacings.unit(332.5),
  margin: `${theme.spacings.unit(10)} ${theme.spacings.unit(9)}`,
  color: lightContrast ? theme.colors.white : theme.colors.paragraphText,
  [theme.breakpointsMediaQuery.up('md')]: {
    fontSize: theme.fonts.utility.size(8),
    margin: `${theme.spacings.unit(10)} auto ${theme.spacings.unit(18.5)} auto`,
  },
}));

export const BreadcrumbLink = styled(StyledLink)(() => ({
  fontSize: 'inherit',
}));
