import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { useWindowSize } from 'usehooks-ts';
import { Responsive } from 'react-alice-carousel';
import Link from 'next/link';

import BaseCarousel from '../base-carousel/BaseCarousel';
import Card from '../../cards/card/Card';
import Repeater from '../../repeater/Repeater';
import PaginationArrows from '../../pagination-arrows/PaginationArrows';
import { SliderConfigs } from '../../../models/domain/interfaces';

import { getStrapiMedia } from '../../../utils/media';
import {
  fromHumanNumberOfColumnsToRepeaterNumberOfColumns,
  workshopSliderConfigs,
} from '../../../utils/carousels';

import {
  Carousel,
  FlexContainer,
  FlexContainerMobile,
  LinkWrapper,
  RepeaterWrapper,
  StyledLink,
  Title,
} from './CarouselWorkshop.style';
import { HideOnDesktop, HideOnMobile } from '../../../styles/shared';

interface Card {
  image?: any;
  title?: string;
  description?: string;
  button?: any;
}

interface WorkshopCarouselProps {
  cards: Card[];
  itemPerPage?: Responsive;
  goToWorkshopsText: string;
  goToWorkshopsLink: string;
  showCarouselTitle: boolean;
  carouselTitle?: string;
  showPaginationLabel?: boolean;
  isWorkshopsPage?: boolean;
  sliderConfigs?: SliderConfigs;
  isPrerenderRequest: boolean;
}

const WorkshopCarousel: React.FC<WorkshopCarouselProps> = (
  props: WorkshopCarouselProps
) => {
  const {
    cards,
    itemPerPage,
    goToWorkshopsText,
    carouselTitle,
    showCarouselTitle = false,
    showPaginationLabel = true,
    isWorkshopsPage = false,
    sliderConfigs = workshopSliderConfigs,
    goToWorkshopsLink,
  } = props;
  const [columns, setColumns] = useState<number>(4);
  const [activeIndex, setActiveIndex] = useState(0);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpointsMediaQuery.up('md'));
  const [, setRerender] = useState<number>(0); // Trick to force re-render

  useEffect(() => {
    setRerender(Date.now());
  }, []);

  const ref = useRef();

  const slidePrev = () => {
    (ref?.current as any)?.current?.slideTo?.(activeIndex - 1);
  };
  const slideNext = () => {
    (ref?.current as any)?.current?.slideTo?.(activeIndex + 1);
  };
  const syncActiveIndex = ({ item }: any) => setActiveIndex(item);

  const { width } = useWindowSize();

  useEffect(() => {
    setActiveIndex(0);

    switch (true) {
      case width > theme.breakpointsMediaQuery.values.lgl:
        setColumns(
          sliderConfigs.numberOfElementsXL ||
            (workshopSliderConfigs.numberOfElementsXL as number) *
              (isWorkshopsPage ? 3 : 1)
        );
        break;
      case width > theme.breakpointsMediaQuery.values.lg:
        setColumns(
          sliderConfigs.numberOfElementsLG ||
            (workshopSliderConfigs.numberOfElementsLG as number) *
              (isWorkshopsPage ? 3 : 1)
        );
        break;
      case width > theme.breakpointsMediaQuery.values.md:
        setColumns(
          sliderConfigs.numberOfElementsMD ||
            (workshopSliderConfigs.numberOfElementsMD as number) *
              (isWorkshopsPage ? 3 : 1)
        );
        break;
      case width > theme.breakpointsMediaQuery.values.sm:
        setColumns(
          sliderConfigs.numberOfElementsSM ||
            (workshopSliderConfigs.numberOfElementsSM as number) *
              (isWorkshopsPage ? 3 : 1)
        );
        break;
      case width > theme.breakpointsMediaQuery.values.xs:
        setColumns(
          sliderConfigs.numberOfElementsXS ||
            (workshopSliderConfigs.numberOfElementsXS as number) *
              (isWorkshopsPage ? 3 : 1)
        );
        break;

      default:
        setColumns(
          sliderConfigs.numberOfElementsXS ||
            (workshopSliderConfigs.numberOfElementsXS as number) *
              (isWorkshopsPage ? 3 : 1)
        );
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  const cardsMapper = useCallback(
    (cards: any[]) =>
      cards?.map(({ attributes, id }) => {
        const imageUrl =
          typeof window === 'undefined' || isDesktop
            ? attributes.thumbnail?.data?.attributes?.url
            : attributes?.mobileThumbnail?.data?.attributes?.url;

        return {
          key: id,
          image: getStrapiMedia(
            imageUrl ?? attributes.thumbnail?.data?.attributes?.url
          ),
          category: attributes.category,
          title: attributes.title,
          description: attributes.description,
          url: attributes.url,
          button: attributes.button,
          overlayColor: attributes.overlayColor,
          isLast: id === -1,
        };
      }),
    [isDesktop]
  );

  const repeaterRender = (items: any[]) => {
    return items.reduce((acc, curr, i) => {
      if (!acc || !acc[Math.floor(i / columns)]) {
        acc = {
          ...acc,
          [Math.floor(i / columns)]: [curr],
        };
      } else {
        acc[`${Math.floor(i / columns)}`].push(curr);
      }
      return acc;
    }, {} as any);
  };

  const mappedCards = cardsMapper(cards);
  const carouselItems = Object.values(
    (cards && Array.isArray(cards) && repeaterRender(mappedCards)) || []
  )?.map((items, i) => (
    <Repeater
      key={i}
      spacing={2}
      cols={{
        xs: fromHumanNumberOfColumnsToRepeaterNumberOfColumns(
          sliderConfigs.numberOfElementsXS ||
            (workshopSliderConfigs.numberOfElementsXS as number)
        ),
        sm: fromHumanNumberOfColumnsToRepeaterNumberOfColumns(
          sliderConfigs.numberOfElementsSM ||
            (workshopSliderConfigs.numberOfElementsSM as number)
        ),
        md: fromHumanNumberOfColumnsToRepeaterNumberOfColumns(
          sliderConfigs.numberOfElementsMD ||
            (workshopSliderConfigs.numberOfElementsMD as number)
        ),
        lg: fromHumanNumberOfColumnsToRepeaterNumberOfColumns(
          sliderConfigs.numberOfElementsLG ||
            (workshopSliderConfigs.numberOfElementsLG as number)
        ),
        xl: fromHumanNumberOfColumnsToRepeaterNumberOfColumns(
          sliderConfigs.numberOfElementsXL ||
            (workshopSliderConfigs.numberOfElementsXL as number)
        ),
      }}
      data={items}
      props={{
        section: 'carousel-workshop',
        isPrerenderRequest: props.isPrerenderRequest,
      }}
      component={Card}
    />
  ));

  const data = mappedCards?.filter((item) => !item.isLast)?.slice(0, 3);

  return (
    <>
      <Carousel>
        <HideOnMobile>
          <FlexContainer
            showCarouselTitle={
              showCarouselTitle || sliderConfigs?.showCarouselTitle
            }
          >
            {(showCarouselTitle || sliderConfigs?.showCarouselTitle) && (
              <Title>{carouselTitle}</Title>
            )}
            {carouselItems?.length > 1 && (
              <PaginationArrows
                label={showPaginationLabel ?? false}
                index={activeIndex}
                totalItems={carouselItems?.length}
                onLeftArrowClick={slidePrev}
                onRightArrowClick={slideNext}
              />
            )}
          </FlexContainer>
          <BaseCarousel
            {...props}
            responsive={itemPerPage}
            mouseTracking={true}
            disableButtonsControls
            disableDotsControls
            activeIndex={activeIndex}
            onSlideChanged={syncActiveIndex}
            items={carouselItems}
            dotsAlignment={'right'}
            autoPlay={sliderConfigs?.autoPlay}
            autoPlayInterval={sliderConfigs?.autoPlayInterval}
            animationDuration={sliderConfigs?.animationDuration}
            animationType={sliderConfigs?.animationType}
            infinite={sliderConfigs?.infinite}
            refFromParentCb={(refFromChild: any) => {
              ref.current = refFromChild;
            }}
          />
        </HideOnMobile>

        <HideOnDesktop>
          {!isWorkshopsPage && (
            <RepeaterWrapper>
              {(showCarouselTitle || sliderConfigs?.showCarouselTitle) && (
                <Title>{carouselTitle}</Title>
              )}
              <Repeater
                spacing={2}
                cols={{
                  xs: fromHumanNumberOfColumnsToRepeaterNumberOfColumns(
                    sliderConfigs.numberOfElementsXS ||
                      (workshopSliderConfigs.numberOfElementsXS as number)
                  ),
                  sm: fromHumanNumberOfColumnsToRepeaterNumberOfColumns(
                    sliderConfigs.numberOfElementsSM ||
                      (workshopSliderConfigs.numberOfElementsSM as number)
                  ),
                  md: fromHumanNumberOfColumnsToRepeaterNumberOfColumns(
                    sliderConfigs.numberOfElementsMD ||
                      (workshopSliderConfigs.numberOfElementsMD as number)
                  ),
                  lg: fromHumanNumberOfColumnsToRepeaterNumberOfColumns(
                    sliderConfigs.numberOfElementsLG ||
                      (workshopSliderConfigs.numberOfElementsLG as number)
                  ),
                  xl: fromHumanNumberOfColumnsToRepeaterNumberOfColumns(
                    sliderConfigs.numberOfElementsXL ||
                      (workshopSliderConfigs.numberOfElementsXL as number)
                  ),
                }}
                data={data}
                props={{
                  section: 'carousel-workshop',
                  isPrerenderRequest: props.isPrerenderRequest,
                }}
                component={Card}
              />
              <LinkWrapper>
                <Link href={goToWorkshopsLink} passHref>
                  <StyledLink>{goToWorkshopsText}</StyledLink>
                </Link>
              </LinkWrapper>
            </RepeaterWrapper>
          )}
          {isWorkshopsPage && (
            <>
              <FlexContainerMobile
                showCarouselTitle={
                  showCarouselTitle || sliderConfigs?.showCarouselTitle
                }
              >
                {(showCarouselTitle || sliderConfigs?.showCarouselTitle) && (
                  <Title>{carouselTitle}</Title>
                )}
                {carouselItems?.length > 1 && (
                  <PaginationArrows
                    label={showPaginationLabel ?? false}
                    index={activeIndex}
                    totalItems={carouselItems?.length}
                    onLeftArrowClick={slidePrev}
                    onRightArrowClick={slideNext}
                  />
                )}
              </FlexContainerMobile>
              <BaseCarousel
                {...props}
                responsive={itemPerPage}
                mouseTracking={true}
                disableButtonsControls
                disableDotsControls
                activeIndex={activeIndex}
                onSlideChanged={syncActiveIndex}
                items={carouselItems}
                dotsAlignment={'right'}
                autoPlay={sliderConfigs?.autoPlay}
                autoPlayInterval={sliderConfigs?.autoPlayInterval}
                animationDuration={sliderConfigs?.animationDuration}
                animationType={sliderConfigs?.animationType}
                infinite={sliderConfigs?.infinite}
                refFromParentCb={(refFromChild: any) => {
                  ref.current = refFromChild;
                }}
              />
            </>
          )}
        </HideOnDesktop>
      </Carousel>
    </>
  );
};

export default WorkshopCarousel;
