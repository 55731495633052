import { styled } from '@mui/material';
import { StarIcon } from '../../assets';
import { mergePersonalization } from '../../utils/style';

export const Subtitle = styled('h2', {
  shouldForwardProp: (prop) =>
    ![
      'fontSize',
      'fontMobileSize',
      'fontMobileWeight',
      'fontMobileWeight',
      'personalization',
    ].includes(prop as string),
})(
  ({
    theme,
    fontSize,
    fontMobileSize,
    fontMobileWeight,
    fontWeight,
    personalization,
  }) =>
    mergePersonalization(
      theme,
      {
        textAlign: 'center',
        color: theme.colors.black,
        marginBottom: theme.spacings.unit(1.75),
        letterSpacing: theme.spacings.unit(0.075),
        textTransform: 'uppercase',

        [theme.breakpointsMediaQuery.up('sm')]: {
          fontSize: fontSize ? `${fontSize}px` : theme.fonts.utility.size(9),
          lineHeight: 1.1,
          fontWeight: fontWeight ? (fontWeight as number) : 600,
        },

        [theme.breakpointsMediaQuery.up('md')]: {
          marginBottom: theme.spacings.unit(4),
          marginLeft: theme.spacings.unit(12),
          marginRight: theme.spacings.unit(12),
          letterSpacing: theme.spacings.unit(0.0875),
        },

        [theme.breakpointsMediaQuery.down('max')]: {},

        [theme.breakpointsMediaQuery.down('xl')]: {},

        [theme.breakpointsMediaQuery.down('lg')]: {},

        [theme.breakpointsMediaQuery.down('md')]: {},

        [theme.breakpointsMediaQuery.down('sm')]: {
          fontSize: fontMobileSize
            ? `${fontMobileSize}px`
            : theme.fonts.utility.size(11),
          fontWeight: fontMobileWeight ? fontMobileWeight : 600,
          lineHeight: 1.1,
        },
      },
      personalization
    )
);

export const Title = styled('h2', {
  shouldForwardProp: (prop) =>
    ![
      'fontSize',
      'fontMobileSize',
      'fontMobileWeight',
      'fontMobileWeight',
      'personalization',
    ].includes(prop as string),
})(
  ({
    theme,
    fontSize,
    fontMobileSize,
    fontMobileWeight,
    fontWeight,
    personalization,
  }) =>
    mergePersonalization(
      theme,
      {
        textAlign: 'center',
        color: theme.colors.primaryText,
        letterSpacing: theme.spacings.unit(0.125),
        marginBottom: theme.spacings.unit(6),
        marginLeft: theme.spacings.unit(12),
        marginRight: theme.spacings.unit(12),

        [theme.breakpointsMediaQuery.up('sm')]: {
          fontSize: fontSize ? `${fontSize}px` : theme.fonts.utility.size(10),
          lineHeight: 1.1,
          fontWeight: fontWeight ? (fontWeight as number) : 400,
        },
        [theme.breakpointsMediaQuery.up('md')]: {
          marginLeft: '25%',
          marginRight: '25%',
          letterSpacing: theme.spacings.unit(0.22),
        },

        [theme.breakpointsMediaQuery.down('max')]: {},

        [theme.breakpointsMediaQuery.down('xl')]: {},

        [theme.breakpointsMediaQuery.down('lg')]: {},

        [theme.breakpointsMediaQuery.down('md')]: {},

        [theme.breakpointsMediaQuery.down('sm')]: {
          fontWeight: fontMobileWeight ? fontMobileWeight : 300,
          letterSpacing: 0,
          fontSize: fontMobileSize
            ? `${fontMobileSize}px`
            : theme.fonts.utility.size(9),
          lineHeight: 1.1,
        },
      },
      personalization
    )
);

export const StarIconStyled = styled(StarIcon)(({ theme }) => ({
  color: theme.colors.primary,
}));

export const IconContainerStyled = styled('div')(() => ({
  textAlign: 'center',
}));
