export const pageview = (url: string) => {
  typeof window !== 'undefined' &&
    (window as any)?.dataLayer?.push({
      event: 'pageview',
      page: url,
    });
};

export const fileDownalod = (linkUrl: string) => {
  const linkUrlSplitted = linkUrl.split('/');
  const fileNameSplitted =
    linkUrlSplitted?.[linkUrlSplitted.length - 1]?.split?.('.');
  const fileName = fileNameSplitted?.[0];
  const fileExtension = fileNameSplitted?.[1];

  typeof window !== 'undefined' &&
    (window as any)?.dataLayer?.push({
      event: 'file_download',
      file_extension: fileExtension || '',
      file_name: fileName || '',
      link_url: linkUrl,
    });
};

export const viewSearchResults = (searchTerm: string) => {
  typeof window !== 'undefined' &&
    (window as any)?.dataLayer?.push({
      event: 'view_search_results',
      search_term: searchTerm,
    });
};

export const formSubmitted = (formGuid: string) => {
  typeof window !== 'undefined' &&
    (window as any)?.dataLayer?.push({
      event: 'hubspot-form-success',
      'hs-form-guid': formGuid,
    });
};
