import { styled } from '@mui/material';
import { HeaderCoverResizeType } from '../../models/enum';
import { ContentWrapper, StyledLink } from '../../styles/shared';

export const OverlayStyled = styled('div', {
  shouldForwardProp: (prop) => !['overlay'].includes(prop as string),
})(({ overlay }) => ({
  backgroundColor: `${overlay ? overlay + '80' : 'transparent'}`,
  gridArea: '1 / 1 / 1 / 1',
  position: 'relative',
}));

export const ContentDivision = styled('div', {
  shouldForwardProp: (prop) => !['maxDimension'].includes(prop as string),
})(({ theme, maxDimension }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: maxDimension ? '100%' : '45%',
  [theme.breakpointsMediaQuery.down('md')]: {
    maxWidth: 'inherit',
  },
}));

export const ImageContainerStyled = styled('div', {
  shouldForwardProp: (prop) => !['headerCoverResize'].includes(prop as string),
})(() => ({
  gridArea: '1 / 1 / 1 / 1',
  '> span': {
    height: '100% !important',
  },
}));

export const StyledVideo = styled('video')(({ objectFit }) => ({
  width: '100%',
  height: '100%',
  top: '50%',
  transform: 'translateY(-50%)',
  position: 'relative',
  objectFit: objectFit || 'cover',
}));

export const ImageContainerDefaultStyled = styled('div', {
  shouldForwardProp: (prop) => !['headerCoverResize'].includes(prop as string),
})(({ theme, headerCoverResize }) => ({
  position: 'relative',
  display: 'block',
  [theme.breakpointsMediaQuery.down('sm')]: {
    minHeight: (() => {
      switch (headerCoverResize) {
        default:
        case HeaderCoverResizeType.DEFAULT_COVER:
          return '30vh';
        case HeaderCoverResizeType.CONTAIN:
          return 'auto';
        case HeaderCoverResizeType.HIDDEN:
          return 0;
      }
    })(),
  },
  [theme.breakpointsMediaQuery.up('sm')]: {
    height: '100%',
    maxHeight: 'none',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    zIndex: -3,
  },
}));

export const CoverContainer = styled('div', {
  shouldForwardProp: (prop) => !['headerCoverResize'].includes(prop as string),
})(({ theme, headerCoverResize }) => ({
  position: 'relative',
  display: 'grid',
  width: '100%',
  color: theme.colors.primaryText,
  '&:after': {
    opacity: 1,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    zIndex: -1,
  },
  [theme.breakpointsMediaQuery.up('sm')]: {
    minHeight: (() => {
      switch (headerCoverResize) {
        default:
        case HeaderCoverResizeType.DEFAULT_COVER:
          return theme.spacings.unit(125);
        case HeaderCoverResizeType.CONTAIN:
          return 'auto';
        case HeaderCoverResizeType.HIDDEN:
          return 0;
      }
    })(),
    color: theme.colors.white,
  },
  [theme.breakpointsMediaQuery.up('md')]: {
    height: (() => {
      switch (headerCoverResize) {
        default:
        case HeaderCoverResizeType.DEFAULT_COVER:
          return theme.spacings.unit(150);
        case HeaderCoverResizeType.CONTAIN:
          return 'auto';
        case HeaderCoverResizeType.HIDDEN:
          return 0;
      }
    })(),
  },
  [theme.breakpointsMediaQuery.up('xl')]: {
    height: (() => {
      switch (headerCoverResize) {
        default:
        case HeaderCoverResizeType.DEFAULT_COVER:
          return theme.spacings.unit(187.5);
        case HeaderCoverResizeType.CONTAIN:
          return 'auto';
        case HeaderCoverResizeType.HIDDEN:
          return 0;
      }
    })(),
  },
}));

export const Wrapper = styled('div', {
  shouldForwardProp: (prop) => !['lightContrast'].includes(prop as string),
})(({ theme, lightContrast }) => ({
  position: 'relative',
  padding: `
    ${theme.spacings.unit(9 / 4)} 
    ${theme.spacings.unit(18)}
    ${theme.spacings.unit(9 / 4)}
    ${theme.spacings.unit(9)}
  `,
  [theme.breakpointsMediaQuery.up('sm')]: {
    color: lightContrast ? theme.colors.white : theme.colors.paragraphText,
    gridArea: '1 / 1 / 1 / 1',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: `
      ${theme.spacings.unit(10)} 
      ${theme.spacings.unit(9)} 
      ${theme.spacings.unit(10)} 
      ${theme.spacings.unit(9)}
    `,
  },
  [theme.breakpointsMediaQuery.up('md')]: {
    padding: `
      ${theme.spacings.unit(14)} 
      ${theme.spacings.unit(10)} 
      ${theme.spacings.unit(14)} 
      ${theme.spacings.unit(20)}
    `,
  },
  [theme.breakpointsMediaQuery.up('lg')]: {
    padding: `
      ${theme.spacings.unit(20)} 
      0
      ${theme.spacings.unit(20)} 
      ${theme.spacings.unit(28)}
    `,
  },
}));

export const Title = styled('h1', {
  shouldForwardProp: (prop) =>
    ![
      'fontSize',
      'fontMobileSize',
      'fontMobileWeight',
      'fontMobileWeight',
    ].includes(prop as string),
})(({ theme, fontSize, fontMobileSize, fontMobileWeight, fontWeight }) => ({
  margin: 0,
  marginTop: theme.fonts.utility.size(6),
  wordBreak: 'break-word',

  [theme.breakpointsMediaQuery.up('sm')]: {
    fontSize: fontSize
      ? `${fontSize * 0.5}px`
      : theme.fonts.utility.size((70 * 0.5) / 2),
    lineHeight: fontSize
      ? `${(fontSize + 4 * 0.5) * 0.5}px`
      : theme.fonts.utility.size((74 * 0.5) / 2),
    fontWeight: fontWeight ? (fontWeight as number) : 500,
  },

  [theme.breakpointsMediaQuery.up('md')]: {
    fontSize: fontSize
      ? `${fontSize * 0.6}px`
      : theme.fonts.utility.size((70 * 0.6) / 2),
    lineHeight: fontSize
      ? `${(fontSize + 4 * 0.6) * 0.6}px`
      : theme.fonts.utility.size((74 * 0.6) / 2),
  },

  [theme.breakpointsMediaQuery.up('lg')]: {
    fontSize: fontSize
      ? `${fontSize * 0.8}px`
      : theme.fonts.utility.size((70 * 0.8) / 2),
    lineHeight: fontSize
      ? `${(fontSize + 4 * 0.8) * 0.8}px`
      : theme.fonts.utility.size((74 * 0.8) / 2),
  },

  [theme.breakpointsMediaQuery.up('xl')]: {
    fontSize: fontSize ? `${fontSize}px` : theme.fonts.utility.size(70 / 2),
    lineHeight: fontSize
      ? `${fontSize + 4}px`
      : theme.fonts.utility.size(74 / 2),
  },

  [theme.breakpointsMediaQuery.down('sm')]: {
    fontSize: fontMobileSize
      ? `${fontMobileSize}px`
      : theme.fonts.utility.size(30 / 2),
    lineHeight: fontMobileSize
      ? `${fontMobileSize + 4}px`
      : theme.fonts.utility.size(34 / 2),
    fontWeight: fontMobileWeight ? fontMobileWeight : 500,
  },
}));

export const Description = styled('div', {
  shouldForwardProp: (prop) =>
    ![
      'textLineLimit',
      'fontSize',
      'fontMobileSize',
      'fontMobileWeight',
      'fontMobileWeight',
    ].includes(prop as string),
})(
  ({
    theme,
    textLineLimit,
    fontSize,
    fontMobileSize,
    fontMobileWeight,
    fontWeight,
  }) => ({
    maxWidth: theme.spacings.unit(600 / 4),
    fontSize: fontSize ? `${fontSize}px` : theme.fonts.utility.size(20 / 2),
    paddingRight: theme.spacings.unit(20 / 4),
    margin: `${theme.spacings.unit(12 / 4)} 0 0 0 `,
    span: {
      display: 'inline-block',
      lineHeight: theme.fonts.utility.lineHeight(30 / 4),
      ...(textLineLimit && {
        maxHeight: `calc(${theme.spacings.unit(30 / 4)} * 3)`,
        overflowY: 'hidden',
      }),
    },
    [theme.breakpointsMediaQuery.down('sm')]: {
      fontSize: fontMobileSize
        ? `${fontMobileSize}px`
        : theme.fonts.utility.size(18 / 2),
      fontWeight: fontMobileWeight ? fontMobileWeight : 300,
      lineHeight: fontMobileSize
        ? `${fontMobileSize + 4}px`
        : theme.fonts.utility.size(22 / 2),
    },
    [theme.breakpointsMediaQuery.up('sm')]: {
      fontSize: fontSize ? `${fontSize}px` : theme.fonts.utility.size(20 / 2),
      lineHeight: fontSize
        ? `${fontSize + 4}px`
        : theme.fonts.utility.size(24 / 2),
      fontWeight: fontWeight ? (fontWeight as number) : 'inherit',
    },
  })
);

export const ButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'start',
  marginTop: theme.spacings.unit(16 / 4),
  [theme.breakpointsMediaQuery.up('md')]: {
    flexGrow: 0,
  },
}));

export const Button = styled('button', {
  shouldForwardProp: (prop) =>
    ![
      'fontSize',
      'fontMobileSize',
      'fontMobileWeight',
      'fontMobileWeight',
    ].includes(prop as string),
})(({ theme, fontSize, fontMobileSize, fontMobileWeight, fontWeight }) => ({
  background: 'transparent',
  border: `${theme.spacings.unit(1 / 4)} solid ${theme.colors.primaryText}`,
  color: theme.colors.primaryText,
  padding: theme.spacings.unit(4),
  alignSelf: 'center',
  textAlign: 'center',
  '&:hover': {
    background: theme.colors.primary,
    border: `${theme.spacings.unit(1 / 4)} solid ${theme.colors.primary}`,
    color: theme.colors.white,
    cursor: 'pointer',
  },
  [theme.breakpointsMediaQuery.up('sm')]: {
    color: theme.colors.white,
    border: `${theme.spacings.unit(1 / 4)} solid ${theme.colors.white}`,
    fontSize: fontSize ? `${fontSize}px` : theme.fonts.utility.size(20 / 2),
    lineHeight: fontSize
      ? `${fontSize + 4}px`
      : theme.fonts.utility.size(24 / 2),
    fontWeight: fontWeight ? (fontWeight as number) : 'inherit',
  },
  [theme.breakpointsMediaQuery.up('md')]: {
    alignSelf: 'start',
  },
  [theme.breakpointsMediaQuery.down('sm')]: {
    fontSize: fontMobileSize
      ? `${fontMobileSize}px`
      : theme.fonts.utility.size(20 / 2),
    lineHeight: fontMobileSize
      ? `${fontMobileSize + 4}px`
      : theme.fonts.utility.size(24 / 2),
    fontWeight: fontMobileWeight ? fontMobileWeight : 'inherit',
  },
}));

export const TextWrapper = styled('div', {
  shouldForwardProp: (prop) => !['lightContrast'].includes(prop as string),
})(({ theme, lightContrast }) => ({
  fontSize: theme.fonts.utility.size(7),
  letterSpacing: theme.spacings.unit(0.0875),
  maxWidth: theme.spacings.unit(332.5),
  margin: `${theme.spacings.unit(10)} ${theme.spacings.unit(9)}`,
  color: lightContrast ? theme.colors.white : theme.colors.paragraphText,
  [theme.breakpointsMediaQuery.up('md')]: {
    fontSize: theme.fonts.utility.size(8),
    margin: `${theme.spacings.unit(10)} auto ${theme.spacings.unit(18.5)} auto`,
  },
}));

export const BreadcrumbLink = styled(StyledLink)(() => ({
  fontSize: 'inherit',
}));
