export const correctHeightProportion = (
  height: number,
  width: number,
  baseWidth = 1920
): number | undefined => {
  if (!height || !width) {
    return undefined;
  }
  return (height / width) * baseWidth;
};
