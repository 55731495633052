import { styled } from '@mui/material';
import { StyledLink } from '../../../styles/shared';

export const CarouselNewsEventsWrapper = styled('div', {
  shouldForwardProp: (prop) => !['lessPadding'].includes(prop as string),
})(({ theme, lessPadding }) => ({
  paddingTop: lessPadding ? 0 : theme.spacings.unit(12.75),
  [theme.breakpointsMediaQuery.up('md')]: {
    paddingTop: lessPadding ? 0 : theme.spacings.unit(16),
  },
}));

export const CTA = styled(StyledLink)(({ theme }) => ({
  textAlign: 'center',
  width: '100%',
  marginTop: theme.spacings.unit(8),
  fontSize: theme.fonts.utility.size(9),
}));
