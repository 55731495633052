import { styled } from '@mui/material';
import { StyledLink } from '../../../styles/shared';

export const OverlayStyled = styled('span', {
  shouldForwardProp: (prop) => !['overlay'].includes(prop as string),
})(({ theme, overlay }) => ({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  zIndex: -2,
  boxShadow: `inset 0 0 0 ${theme.spacings.unit(500)} ${
    overlay ? overlay + '80' : 'transparent'
  }`,
  [theme.breakpointsMediaQuery.up('sm')]: {
    display: 'none',
  },
}));

export const CardImageContainerStyled = styled('div', {
  shouldForwardProp: (prop) =>
    !['isLast', 'background', 'withOverlay'].includes(prop as string),
})(({ theme }) => ({
  display: 'block',
  position: 'relative',
  zIndex: '-3',
  width: '100%',
  aspectRatio: '1/1',
  [theme.breakpointsMediaQuery.up('sm')]: {
    height: '100%',
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    aspectRatio: 'auto',
  },
}));

export const CardContainerStyled = styled(
  ({ children, ...props }: any) => {
    return <div {...props}>{children}</div>;
  },
  {
    shouldForwardProp: (prop) =>
      !['overlay', 'overlayHover'].includes(prop as string),
  }
)(({ theme, overlay, overlayHover }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  [theme.breakpointsMediaQuery.up('sm')]: {
    cursor: 'pointer',
    '&:after': {
      content: '""',
      opacity: 1,
      boxShadow: `inset 0 0 0 ${theme.spacings.unit(500)} ${
        overlay ? overlay + '80' : 'transparent'
      }`,
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      zIndex: -1,
    },
    '&:hover': {
      '&:after': {
        boxShadow: `inset 0 0 0 ${theme.spacings.unit(500)} ${
          overlayHover ? overlayHover + '80' : theme.colors.overlayColor + '80'
        }`,
      },
    },
  },
}));

export const CardStyled = styled(
  (props: any) => {
    return (
      <div className={props.className} section={props.section}>
        {props.children}
      </div>
    );
  },
  {
    shouldForwardProp: (prop) =>
      !['isLast', 'background', 'withOverlay'].includes(prop as string),
  }
)(({ theme, section, color, isLast }) => ({
  padding: `${theme.spacings.unit(18 / 4)} ${theme.spacings.unit(
    36 / 4
  )} ${theme.spacings.unit(36 / 4)}`,
  backgroundBlendMode: 'multiply',
  boxShadow: '0',
  color: theme.colors.primaryText,
  [theme.breakpointsMediaQuery.up('sm')]: {
    boxShadow: '0 1px 0 rgb(0 0 0 / 25%)',
    minHeight: theme.spacings.unit(100),
    height: '100%',
    padding: `${theme.spacings.unit(21)} ${theme.spacings.unit(
      9
    )} ${theme.spacings.unit(16.25)} ${theme.spacings.unit(9)}`,
    color: theme.colors.white,
    ...([
      'slider-products',
      'carousel-workshop',
      'carousel-news-events',
    ].includes(section) && {
      display: 'flex',
      flexDirection: 'column',
    }),
    ...(['carousel-news-events'].includes(section) && {
      color: color,
      ...(isLast && {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }),
    }),
    ...(['carousel-workshop', 'carousel-news-events'].includes(section) && {
      minHeight: theme.spacings.unit(111),
      height: '100%',
    }),
  },
  [theme.breakpointsMediaQuery.up('md')]: {
    padding: theme.spacings.unit(16),
    ...(['slider-products'].includes(section) && {
      minHeight: theme.spacings.unit(500 / 4),
      height: '100%',
    }),
    ...(['carousel-workshop', 'carousel-news-events'].includes(section) && {
      padding: theme.spacings.unit(12.5),
    }),
  },
}));

export const Category = styled('h3')(({ theme, section }) => ({
  marginTop: theme.spacings.unit(12 / 4),
  marginBottom: theme.spacings.unit(4),
  fontSize: theme.fonts.utility.size(6),
  fontWeight: 'normal',
  ...(['carousel-workshop', 'carousel-news-events', 'slider-products'].includes(
    section
  ) && {
    textTransform: 'uppercase',
  }),
  [theme.breakpointsMediaQuery.up('md')]: {
    fontSize: theme.fonts.utility.size(7),
    ...([
      'carousel-workshop',
      'carousel-news-events',
      'slider-products',
    ].includes(section) && {
      marginTop: 0,
    }),
  },
}));

export const Title = styled('h3')(({ theme, section }) => ({
  margin: `${theme.spacings.unit(1)} 0`,
  ...(['carousel-workshop', 'carousel-news-events', 'slider-products'].includes(
    section
  ) && {
    fontSize: theme.fonts.utility.size(11.5),
    letterSpacing: theme.spacings.unit(0.1425),
  }),
}));

export const Description = styled('p')(({ theme, section }) => ({
  fontWeight: 'normal',
  margin: `${theme.spacings.unit(1)} 0`,
  fontSize: theme.fonts.utility.size(10),
  ...(['carousel-workshop', 'carousel-news-events'].includes(section) && {
    letterSpacing: theme.spacings.unit(0.125),
    maxWidth: theme.spacings.unit(87.5),
  }),
  ...(['slider-products'].includes(section) && {
    fontWeight: '200',
  }),
  [theme.breakpointsMediaQuery.up('md')]: {
    ...(['slider-products'].includes(section) && {
      fontSize: theme.fonts.utility.size(20),
    }),
    ...(['carousel-workshop', 'carousel-news-events'].includes(section) && {
      letterSpacing: theme.spacings.unit(0.1425),
    }),
  },
  [theme.breakpointsMediaQuery.down('sm')]: {
    fontSize: theme.fonts.utility.size(9),
    fontWeight: 300,
    letterSpacing: 0,
  },
}));

export const LinkWrapper = styled('div', {
  shouldForwardProp: (prop) => !['isLast'].includes(prop as string),
})(({ theme, isLast }) => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  [theme.breakpointsMediaQuery.up('sm')]: {
    alignItems: 'end',
    flexGrow: 2,
  },
  [theme.breakpointsMediaQuery.up('md')]: {
    alignItems: 'start',
    ...(isLast && {
      alignItems: 'center',
      a: {
        color: theme.colors.primaryText,
      },
    }),
  },
}));

export const CTA = styled(StyledLink, {
  shouldForwardProp: (prop) => !['isLast'].includes(prop as string),
})(({ theme, section, color, isLast }) => ({
  marginTop: theme.spacings.unit(8),
  color: theme.colors.primaryText,
  ...(['carousel-workshop', 'carousel-news-events', 'slider-products'].includes(
    section
  ) && {
    fontSize: theme.fonts.utility.size(8),
    width: theme.spacings.unit(87.5),
  }),
  ...(['carousel-news-events', 'carousel-workshop'].includes(section) && {
    ...(isLast && {
      color: color,
      marginTop: theme.spacings.unit(0),
    }),
  }),
  [theme.breakpointsMediaQuery.up('sm')]: {
    color: theme.colors.white,
  },
  [theme.breakpointsMediaQuery.up('md')]: {
    ...([
      'carousel-workshop',
      'carousel-news-events',
      'slider-products',
    ].includes(section) && {
      fontSize: theme.fonts.utility.size(9),
    }),
  },
}));
