import React from 'react';
import { FieldProps } from 'formik';
import { FormControlProps } from '@mui/material';
import {
  FormControlLabelStyled,
  RadioGroupStyled,
  RadioLabelStyled,
  RadioLabelStyledSub,
  RadioSectionStyled,
  RadioStyled,
} from './Radio.style';

export enum RadioType {
  'primary',
  'secondary',
  'disabled',
  'contactFormModal',
  'contactFormPage',
  'workshop',
}

interface IOptions {
  label: string;
  value: string;
  disabled?: boolean;
}

export interface RadioProps extends FormControlProps, FieldProps {
  label: string;
  subText: string;
  options: IOptions[];
  multiple?: boolean;
  onChangeValue?(e: React.ChangeEvent<any>): void;
  radioType: string;
  section: string;
}

const Radio: React.FC<RadioProps> = ({
  form,
  field,
  subText,
  label,
  options,
  onChangeValue,
  radioType,
  section,
  ...props
}: RadioProps) => {
  const id = `${section}_${field?.name}`;

  return (
    <RadioSectionStyled {...props}>
      {label && (
        <RadioLabelStyled radioType={radioType} htmlFor={id}>
          {label}
        </RadioLabelStyled>
      )}
      {subText && (
        <RadioLabelStyledSub radioType={radioType} htmlFor={id}>
          {subText}
        </RadioLabelStyledSub>
      )}
      <RadioGroupStyled
        radioType={radioType}
        name={field?.name}
        value={field?.value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
          form?.setFieldValue?.(field?.name, e?.target?.value);
          onChangeValue && onChangeValue(e);
        }}
        onBlur={field?.onBlur}
      >
        {options?.map?.((item, i) => (
          <div key={i}>
            <FormControlLabelStyled
              key={`${field?.name}-r-${i}`}
              value={item?.value}
              control={
                <RadioStyled
                  key={item?.value}
                  value={item?.value}
                  label={item?.label}
                  disabled={item?.disabled}
                  checked={item?.value === field?.value}
                />
              }
              label={item?.label}
            />
          </div>
        ))}
      </RadioGroupStyled>
    </RadioSectionStyled>
  );
};

export default Radio;
