import TableHeaderCell from '../table-header-cell/TableHeaderCell';
import { TableHeadMUI } from './TableHeader.style';
import { TableRowMUI } from '../Table.style';

const TableHeader = ({
  children,
  collapse,
  data,
  cell,
  className,
}: {
  children?: any;
  collapse?: boolean;
  data?: string[];
  cell?: any;
  className?: string;
}) => {
  const Cell = cell || TableHeaderCell;

  return (
    <TableHeadMUI className={className}>
      <TableRowMUI>
        {children || (
          <>
            {collapse ? <Cell size={'icon'}></Cell> : <></>}
            {data?.map((value: string, index: number) => {
              return (
                <Cell key={index} title={value}>
                  {value}
                </Cell>
              );
            })}
          </>
        )}
      </TableRowMUI>
    </TableHeadMUI>
  );
};

export default TableHeader;
