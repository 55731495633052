import { ArrowDropDownIcon, ArrowRightIcon } from '../../../assets';

import { IconButtonMUI } from './TableIconCollapse.style';

const TableBodyRowCell = ({
  onClick,
  open,
}: {
  onClick?: () => void;
  open: boolean;
}) => {
  return (
    <IconButtonMUI onClick={onClick} size="small">
      {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
    </IconButtonMUI>
  );
};

export default TableBodyRowCell;
