import * as React from 'react';
import { useRouter } from 'next/router';
import { defaultInternationalization, defaultLanguage } from 'corporate-types';
import { internationalizationToLanguages } from 'packages/corporate-utils/bin';

import { getTranslations } from '../../utils/localize';

import {
  Pagination,
  PageIndicator,
  Page,
  StyledArrowCircleLeftIcon,
  StyledArrowCircleRightIcon,
} from './PaginationArrows.style';
import { useCallback, useEffect } from 'react';

interface IProps {
  index: number;
  totalItems: number;
  position?: string;
  label?: boolean;
  onLeftArrowClick: () => void;
  onRightArrowClick: () => void;
}

const PaginationArrows: React.FunctionComponent<IProps> = (props: IProps) => {
  const { label = true } = props;
  const [translations, setTranslations] = React.useState<any>();

  const handleLeftClick = () => {
    props.onLeftArrowClick();
  };

  const handleRightClick = () => {
    props.onRightArrowClick();
  };

  const { locale, asPath } = useRouter();

  const getTranlationCallback = useCallback(async () => {
    const languages = await getTranslations(
      internationalizationToLanguages(
        locale || defaultInternationalization,
        asPath
      ) || defaultLanguage
    );
    setTranslations(languages);
  }, [asPath, locale]);

  useEffect(() => {
    getTranlationCallback();
  }, [getTranlationCallback]);

  return (
    <Pagination>
      {label && (
        <PageIndicator>
          {translations?.components?.pagination?.pageFrom}
          <Page>{props.index + 1}</Page>
          {translations?.components?.pagination?.pageTo}
          <Page>{props.totalItems}</Page>
        </PageIndicator>
      )}
      <StyledArrowCircleLeftIcon
        disabled={props.index === 0}
        onClick={props.index === 0 ? () => {} : handleLeftClick}
      />
      <StyledArrowCircleRightIcon
        disabled={props.index === props.totalItems - 1}
        onClick={
          props.index === props.totalItems - 1 ? () => {} : handleRightClick
        }
      />
    </Pagination>
  );
};

export default PaginationArrows;
