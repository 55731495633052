import React, { useEffect, useState, useRef } from 'react';
import BaseCarousel from '../base-carousel/BaseCarousel';
import Repeater from '../../repeater/Repeater';
import { useWindowSize } from 'usehooks-ts';
import { Product, SliderConfigs } from '../../../models/domain/interfaces';
import CardOtherProducts from '../../cards/card-other-products/CardOtherProducts';
import { TitleDiv, Title } from './CarouselProductsForNews.style';
import { useTheme } from '../../../theme';
import PaginationArrows from '../../pagination-arrows/PaginationArrows';
import {
  defaultSliderConfigs,
  fromHumanNumberOfColumnsToRepeaterNumberOfColumns,
} from '../../../utils/carousels';

interface ProductCarouselProps {
  products: Product[];
  translations: any;
  sliderConfigs?: SliderConfigs;
  title: string;
  isPrerenderRequest: boolean;
}

const ProductCarouselForNews: React.FC<ProductCarouselProps> = ({
  sliderConfigs = defaultSliderConfigs,
  ...props
}: ProductCarouselProps) => {
  const [columns, setColumns] = useState<number>(4);
  const [activeIndex, setActiveIndex] = useState(0);
  const { state: themeState } = useTheme();
  const ref = useRef();

  const slidePrev = () => {
    (ref?.current as any)?.current?.slideTo?.(activeIndex - 1);
  };
  const slideNext = () => {
    (ref?.current as any)?.current?.slideTo?.(activeIndex + 1);
  };
  const syncActiveIndex = ({ item }: any) => setActiveIndex(item);

  const { width } = useWindowSize();
  useEffect(() => {
    setActiveIndex(0);

    switch (true) {
      case width > themeState.theme.breakpointsMediaQuery.values.lgl:
        setColumns(
          sliderConfigs.numberOfElementsXL ||
            (defaultSliderConfigs.numberOfElementsXL as number)
        );
        break;
      case width > themeState.theme.breakpointsMediaQuery.values.lg:
        setColumns(
          sliderConfigs.numberOfElementsLG ||
            (defaultSliderConfigs.numberOfElementsLG as number)
        );
        break;
      case width > themeState.theme.breakpointsMediaQuery.values.md:
        setColumns(
          sliderConfigs.numberOfElementsMD ||
            (defaultSliderConfigs.numberOfElementsMD as number)
        );
        break;
      case width > themeState.theme.breakpointsMediaQuery.values.sm:
        setColumns(
          sliderConfigs.numberOfElementsSM ||
            (defaultSliderConfigs.numberOfElementsSM as number)
        );
        break;
      case width > themeState.theme.breakpointsMediaQuery.values.xs:
      default:
        setColumns(
          sliderConfigs.numberOfElementsXS ||
            (defaultSliderConfigs.numberOfElementsXS as number)
        );
        break;
    }
  }, [
    sliderConfigs.numberOfElementsLG,
    sliderConfigs.numberOfElementsMD,
    sliderConfigs.numberOfElementsSM,
    sliderConfigs.numberOfElementsXL,
    sliderConfigs.numberOfElementsXS,
    themeState.theme.breakpointsMediaQuery.values.lg,
    themeState.theme.breakpointsMediaQuery.values.lgl,
    themeState.theme.breakpointsMediaQuery.values.md,
    themeState.theme.breakpointsMediaQuery.values.sm,
    themeState.theme.breakpointsMediaQuery.values.xs,
    width,
  ]);

  const repeaterRender = (items: any[]) => {
    return items.reduce((acc, curr, i) => {
      if (!acc || !acc[Math.floor(i / columns)]) {
        acc = {
          ...acc,
          [Math.floor(i / columns)]: [curr],
        };
      } else {
        acc[`${Math.floor(i / columns)}`].push(curr);
      }
      return acc;
    }, {} as any);
  };

  const carouselItems = Object.values(repeaterRender(props.products))?.map(
    (items, i) => (
      <Repeater
        key={i}
        spacing={5}
        cols={{
          xs: fromHumanNumberOfColumnsToRepeaterNumberOfColumns(
            sliderConfigs.numberOfElementsXS ||
              (defaultSliderConfigs.numberOfElementsXS as number)
          ),
          sm: fromHumanNumberOfColumnsToRepeaterNumberOfColumns(
            sliderConfigs.numberOfElementsSM ||
              (defaultSliderConfigs.numberOfElementsSM as number)
          ),
          md: fromHumanNumberOfColumnsToRepeaterNumberOfColumns(
            sliderConfigs.numberOfElementsMD ||
              (defaultSliderConfigs.numberOfElementsMD as number)
          ),
          lg: fromHumanNumberOfColumnsToRepeaterNumberOfColumns(
            sliderConfigs.numberOfElementsLG ||
              (defaultSliderConfigs.numberOfElementsLG as number)
          ),
          xl: fromHumanNumberOfColumnsToRepeaterNumberOfColumns(
            sliderConfigs.numberOfElementsXL ||
              (defaultSliderConfigs.numberOfElementsXL as number)
          ),
        }}
        data={items}
        props={{
          translations: props.translations,
          isPrerenderRequest: props.isPrerenderRequest,
        }}
        component={CardOtherProducts}
      />
    )
  );

  return (
    <>
      <TitleDiv>
        <Title>{props?.title || ''}</Title>
        <PaginationArrows
          index={activeIndex}
          totalItems={carouselItems.length}
          onLeftArrowClick={slidePrev}
          onRightArrowClick={slideNext}
        />
      </TitleDiv>
      <BaseCarousel
        {...props}
        mouseTracking={true}
        disableButtonsControls
        disableDotsControls
        activeIndex={activeIndex}
        onSlideChanged={syncActiveIndex}
        items={carouselItems}
        autoPlay={sliderConfigs.autoPlay}
        autoPlayInterval={sliderConfigs.autoPlayInterval}
        animationDuration={sliderConfigs.animationDuration}
        animationType={sliderConfigs.animationType}
        infinite={sliderConfigs.infinite}
        refFromParentCb={(refFromChild: any) => {
          ref.current = refFromChild;
        }}
      />
    </>
  );
};

export default ProductCarouselForNews;
