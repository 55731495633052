import React from 'react';
import CarouselWorkshop from '../../../components/carousel/carousel-workshop/CarouselWorkshop';
import { StyledContentWrapper } from './SliderWorkshop.style';
import SectionHeader from '../../../components/section-header/SectionHeader';
import { SliderConfigs, Slug } from '../../../models/domain/interfaces';
import {
  defaultSliderConfigs,
  workshopSliderConfigs,
} from '../../../utils/carousels';
import { getWorkshop, getWorkshops } from '../../../utils/api';
import { normalizeAttributesWorkshopsSliders } from '../../../utils/workshops';
import { Pages } from '../../../models/domain/enum';
import { Logger } from 'corporate-utils';

interface SliderWorkshopProps {
  cards: any[];
  mainTitle: string;
  title: string;
  mainTitleFontSize?: number;
  titleFontSize?: number;
  mainTitleMobileFontSize?: number;
  titleMobileFontSize?: number;
  titleMobileFontWeight?: number;
  titleFontWeight?: number;
  mainTitleMobileFontWeight?: number;
  mainTitleFontWeight?: number;
  size: any;
  showCarouselTitle?: boolean;
  showPaginationLabel?: boolean;
  isWorkshopsPage?: boolean;
  sliderConfigs?: SliderConfigs;
}

const SliderWorkshop: React.FC<{
  data: SliderWorkshopProps;
  translations: any;
  isPrerenderRequest: boolean;
}> = ({ data, translations, isPrerenderRequest }) => {
  const {
    mainTitle,
    title,
    cards,
    showCarouselTitle,
    showPaginationLabel,
    isWorkshopsPage,
    sliderConfigs,
  } = data ?? {};

  if (!cards || !cards?.length) return <></>;
  return (
    <StyledContentWrapper>
      <SectionHeader
        subtitle={mainTitle ?? ''}
        title={title ?? ''}
        titleFontSize={data?.titleFontSize}
        mainTitleFontSize={data?.mainTitleFontSize}
        titleMobileFontSize={data?.titleMobileFontSize}
        mainTitleMobileFontSize={data?.mainTitleMobileFontSize}
        mainTitleFontWeight={data?.mainTitleFontWeight}
        mainTitleMobileFontWeight={data?.mainTitleMobileFontWeight}
        titleFontWeight={data?.titleFontWeight}
        titleMobileFontWeight={data?.titleMobileFontWeight}
      />
      <CarouselWorkshop
        cards={cards}
        isWorkshopsPage={isWorkshopsPage}
        showCarouselTitle={
          !!(showCarouselTitle || sliderConfigs?.showCarouselTitle)
        }
        showPaginationLabel={showPaginationLabel}
        goToWorkshopsText={
          translations?.pages?.home?.workshopSlider?.goToWorkshops
        }
        goToWorkshopsLink={translations?.urls?.workshops}
        carouselTitle={
          translations?.pages?.home?.workshopSlider?.workshopsCarouselTitle
        }
        sliderConfigs={sliderConfigs ?? defaultSliderConfigs}
        isPrerenderRequest={isPrerenderRequest}
      />
    </StyledContentWrapper>
  );
};

export const getServerSideProps = async (
  section: any,
  translations: any,
  slugAnalyzed: Slug,
  isPrerenderRequest: boolean
) => {
  switch (slugAnalyzed.type) {
    default:
    case Pages.HOME: {
      let workshops: any[] = [];

      try {
        workshops = normalizeAttributesWorkshopsSliders(
          (
            await getWorkshops({
              locale: slugAnalyzed.locale,
              pageSize: 6,
              sort: ['publishedAt:desc'],
              datesFormat: translations?.date?.sliderNewsEvents?.format,
            })
          )?.data,
          translations,
          slugAnalyzed.locale,
          isPrerenderRequest
        );
      } catch (error) {
        Logger.error(error);
      }

      if (!section?.sliderConfigs?.forceHideGoToArchive) {
        workshops.push({
          id: -1,
          attributes: {
            overlayColor: '#EFEFEF',
            button: {
              link: `/${slugAnalyzed.locale}/${translations?.urls?.workshops}`,
              text: translations?.pages?.home?.workshopSlider?.goToWorkshops,
            },
          },
        });
      }

      section.cards = workshops;
      break;
    }

    case Pages.WORKSHOPS: {
      const workshops = await getWorkshops({
        locale: slugAnalyzed.locale,
        pageSize: 12,
        sort: ['date:desc'],
        datesFormat: translations?.date?.workshop?.format,
        filters: {
          registration: {
            $eq: false,
          },
        },
      });

      section.isWorkshopsPage = true;
      section.cards = normalizeAttributesWorkshopsSliders(
        workshops?.data,
        translations,
        slugAnalyzed.locale,
        isPrerenderRequest
      );

      break;
    }

    case Pages.WORKSHOP: {
      const workshop =
        slugAnalyzed.workshopSlug &&
        (await getWorkshop({
          slug: slugAnalyzed.workshopSlug,
          locale: slugAnalyzed.locale,
          dateFormat: translations?.date?.workshop?.format,
        }));

      const workshops = normalizeAttributesWorkshopsSliders(
        (
          await getWorkshops({
            locale: slugAnalyzed.locale,
            pageSize: 10,
            filters: {
              id: {
                $ne: workshop?.id as number,
              },
            },
            sort: ['date:desc'],
            datesFormat: translations?.date?.workshop?.format,
          })
        )?.data,
        translations,
        slugAnalyzed.locale,
        isPrerenderRequest
      );

      if (!section?.sliderConfigs?.forceHideGoToArchive) {
        workshops.push({
          id: -1,
          attributes: {
            overlayColor: '#EFEFEF',
            button: {
              link:
                section?.sliderConfigs?.alternativeLink ||
                `/${slugAnalyzed.locale}/${translations?.urls?.workshops}`,
              text:
                section?.sliderConfigs?.alternativeLinkText ||
                translations?.pages?.home?.workshopSlider?.goToWorkshops,
            },
          } as any,
          isPrerenderRequest: isPrerenderRequest,
        });
      }

      section.cards = workshops;
      section.showCarouselTitle = true;
      section.showPaginationLabel = true;
      section.sliderConfigs = workshopSliderConfigs;
      break;
    }
  }
};

export default SliderWorkshop;
