import SimpleSelect from '../../components/select/Select';
import { styled } from '@mui/material';

export const SelectStyled = styled((props: any) => <SimpleSelect {...props} />)(
  ({ theme }) => ({
    maxWidth: theme.spacings.unit(30),

    lineHeight: '1rem',
    borderTop: 'transparent',
    borderRight: 'transparent',
    borderLeft: 'transparent',
    '& select': {
      color: theme.colors.primaryText,
      backgroundColor: theme.colors.background,
    },
    '& .MuiNativeSelect-icon path': {
      fill: theme.colors.primaryText,
    },
  })
);
