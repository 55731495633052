import { styled } from '@mui/material';
import Table from '../../components/table/Table';

export const MainPagination = styled('div')(({ theme }) => ({
  marginBottom: '2rem',
  display: 'flex',
  flexAlign: 'center',
  justifyContent: 'space-between',
}));

export const TableStyled = styled((props: any) => (
  <Table className={props.className} {...props} />
))(({ theme }) => ({
  ['& th:nth-of-type(1)']: {
    width: '60%',
  },
  ['& th:nth-of-type(2)']: {
    width: '30%',
  },
  ['& th:nth-of-type(3)']: {
    width: '10%',
  },
  margin: `${theme.spacings.unit(4)} 0`,
}));

export const Label = styled('label')(({ theme }) => ({
  marginRight: '1rem',
}));

export const StyledLink = styled('a')(({ theme }) => ({
  cursor: 'pointer',
  color: 'inherit',
  textDecoration: 'underline',
}));

export const StyledHeader = styled('h2', {
  shouldForwardProp: (prop) =>
    ![
      'fontSize',
      'fontMobileSize',
      'fontMobileWeight',
      'fontMobileWeight',
    ].includes(prop as string),
})(({ theme, fontSize, fontMobileSize, fontMobileWeight, fontWeight }) => ({
  color: theme.colors.primary,
  [theme.breakpointsMediaQuery.down('sm')]: {
    fontSize: fontMobileSize ? `${fontMobileSize}px` : 'inherit',
    lineHeight: fontMobileSize ? `${fontMobileSize + 4}px` : 'inherit',
    fontWeight: fontMobileWeight ? fontMobileWeight : 300,
  },
  [theme.breakpointsMediaQuery.up('sm')]: {
    fontSize: fontSize ? `${fontSize}px` : 'inherit',
    lineHeight: fontSize ? `${fontSize + 4}px` : 'inherit',
    fontWeight: fontWeight ? (fontWeight as number) : 300,
  },
}));

export const StyledSubtitle = styled('div', {
  shouldForwardProp: (prop) =>
    ![
      'fontSize',
      'fontMobileSize',
      'fontMobileWeight',
      'fontMobileWeight',
    ].includes(prop as string),
})(({ theme, fontSize, fontMobileSize, fontMobileWeight, fontWeight }) => ({
  margin: `${theme.spacings.unit(5)} 0`,
  [theme.breakpointsMediaQuery.down('sm')]: {
    fontSize: fontMobileSize ? `${fontMobileSize}px` : 'inherit',
    lineHeight: fontMobileSize ? `${fontMobileSize + 4}px` : 'inherit',
    fontWeight: fontMobileWeight ? fontMobileWeight : 300,
  },
  [theme.breakpointsMediaQuery.up('sm')]: {
    fontSize: fontSize ? `${fontSize}px` : 'inherit',
    lineHeight: fontSize ? `${fontSize + 4}px` : 'inherit',
    fontWeight: fontWeight ? (fontWeight as number) : 300,
  },
}));
