import { TableHeaderCell as TableHeaderCellStyled } from './TableHeaderCell.style';

const TableHeaderCell = ({
  size,
  children,
  ...props
}: {
  size?: 'icon' | 'medium' | 'small';
  children?: any;
}) => {
  return (
    <TableHeaderCellStyled size={size} {...props}>
      {children}
    </TableHeaderCellStyled>
  );
};
export default TableHeaderCell;
