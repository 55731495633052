import { TableRowMUI } from './TableBodyRow.style';
import React from 'react';

const TableBodyRow = ({
  children,
  open,
  className,
  onClick,
  colorOnHover,
}: {
  children?: any;
  open?: boolean;
  className?: string;
  colorOnHover?: boolean;
  onClick?: (e?: any) => void;
}) => {
  const handleClick = (event: React.ChangeEvent<unknown>) => {
    if (onClick && !((event as any)?.target?.tagName?.toLowerCase() === 'a'))
      onClick();
  };
  return (
    <TableRowMUI
      className={className}
      open={open}
      onClick={handleClick}
      colorOnHover={colorOnHover || true}
    >
      {children}
    </TableRowMUI>
  );
};

export default TableBodyRow;
