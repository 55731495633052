import { styled } from '@mui/material';
import { StyledLink } from '../../../styles/shared';

export const CardStyled = styled('div')(({ theme }) => ({
  backgroundColor: theme.colors.secondaryBackground,
  padding: `${theme.spacings.unit(2)} ${theme.spacings.unit(
    6
  )} ${theme.spacings.unit(6)} ${theme.spacings.unit(6)}`,
  cursor: 'pointer',
}));

export const Category = styled('p')(({ theme }) => ({
  color: theme.colors.primary,
  textTransform: 'uppercase',
  marginTop: theme.spacings.unit(25 / 4),
  marginBottom: 0,
  fontSize: theme.fonts.utility.size(10.72 / 2),
  fontWeight: 'bold',
}));

export const Name = styled('h3')(({ theme }) => ({
  color: theme.colors.secondaryText,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  marginTop: theme.spacings.unit(1),
}));

export const ProductImgContainer = styled('div')(() => ({
  aspectRatio: '1/1',
  width: '100%',
  mixBlendMode: 'multiply',
  position: 'relative',
  transition: 'transform 0.2s',
  img: {
    padding: '5% !important',
  },
  '&:hover': {
    transform: 'scale3d(1.10, 1.10, 1.10)',
  },
}));

export const StyledProductLink = styled(StyledLink)(({ theme }) => ({
  fontSize: theme.fonts.utility.size(7),
  margin: `${theme.spacings.unit(6)} 0 ${theme.spacings.unit(2)} 0`,
}));

export const NameLinkStyled = styled('a')(({ theme }) => ({
  textDecoration: 'none',
}));
