import { styled } from '@mui/material';
import { TableRow } from '@mui/material';

export const TableRowMUI = styled(
  (props: any) => (
    <TableRow className={props?.className} {...props}>
      {props?.children}
    </TableRow>
  ),
  {
    shouldForwardProp: (prop) =>
      prop !== 'showPadding' && prop !== 'open' && prop !== 'colorOnHover',
  }
)(
  ({
    theme,
    open,
    showPadding,
    className,
    children,
    colorOnHover,
    ...props
  }) => {
    return {
      ['& > .MuiTableCell-root']: {
        padding: theme.spacings.unit(4),
        border: '0',
      },
      '&:nth-of-type(odd)': {
        backgroundColor: theme.tables.oddRowBackgroundColor,
      },
      '&:nth-of-type(even)': {
        backgroundColor: theme.tables.evenRowBackgroundColor,
      },
      // hide last border
      '& > td': {
        color: theme.tables.rowColor,
      },
      '&:hover': colorOnHover
        ? {
          backgroundColor: '#CFD8DC',
          cursor: 'pointer',
        }
        : null,
    };
  }
);
