import { styled } from '@mui/material';
import { TableCellMUI } from '../Table.style';

export const TableBodyRowCell = styled(
  (props: any) => (
    <TableCellMUI ref={props?.ref} className={props.className} {...props}>
      {props?.children}
    </TableCellMUI>
  ),
  {
    shouldForwardProp: (prop) => true,
  }
)(({ theme, ref, className, children, ...props }) => {
  return {
    color: theme.colors.primaryText,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: theme.tables.minWidthCell,
  };
});
